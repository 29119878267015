import React from 'react'
import BaseComponent from './BaseComponent'
import Reports from './Reports'
import Invoices from './Invoices'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { page: this.props.match.params.page ? this.props.match.params.page : "r", type: this.props.match.params.type ? this.props.match.params.type : "list" }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/reports/r" : "/en/reports/r")
    }
  }


  render () {
    let pageInner = null

    switch(this.state.page) {
      case 'r':
        pageInner = <Reports user={ this.props.user } {...this.props} showAdd={ this.props.match.params.type === "add" ? true : false }/>
      break
      case 'i':
        pageInner = <Invoices user={ this.props.user } {...this.props}/>
      break
      default:
        pageInner = <Reports user={ this.props.user } {...this.props} showAdd={ this.props.match.params.type === "add" ? true : false }/>
      break
    }

    return (
      <section className="users-page">
        { pageInner }
      </section>
    )
  }
}
