import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import _ from 'lodash'
import Modal from './Modal'
import Select from './Select'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      profile: {filters:[]}, profileS: [], itemPopup: false, id: null, rewrite: {},
      carClassMap: [],
      preloader: true
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'changeValue', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'handleInputChange',
      'deleteSuccess',
      'update',
      'validate'
    )

    this.endpoint = "profiles"

    this.days = {
      "MONDAY_FRIDAY": "PON-PIA",
      "MONDAY_SATURDAY": "PON-SOB"
    }

    this.listItems()

    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ profile: {}, itemPopup: false, id: null, rewrite: {} })
    this.listItems()
  }

  validate(fields) {
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/manage/mpk" : "/en/manage/mpk")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData.fields && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {    
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state[type]

    if (event.target.pattern) {
      let pattern = new RegExp(event.target.pattern)
      value = pattern.test(event.target.value) ? event.target.value : item[name] || "";
    }

    item[name] = value

    if( event.target.className === "input-error" ) event.target.className = ""

    this.setState({
      [type]: item
    })
  }

  listItems() {
    this.setState({ preloader: true })
    this.shortFetch("POST", this.endpoint+"", {}, this.successListItems)
  }

  successListItems(data) {    
    let cars = []
    for (var i = 0; i < Object.entries(data.result.carClassMap).length; i++) {
      let item = { id: Object.entries(data.result.carClassMap)[i][0], name: Object.entries(data.result.carClassMap)[i][1] }
      cars.push(item)
    }
    this.setState({ data: data.result.profiles, itemPopup: false, carClassMap: cars, carClassMapM: data.result.carClassMap })
    setTimeout(() => { this.setState({ preloader: false }) }, 100)
  }

  addItem() {
    this.setState({ profile: {filters: []}, itemPopup: true, action: "add" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    let profile = data.result.profile
    profile.maxTotalAmountGr = profile.maxTotalAmountGr/100 || null
    profile.warnAmountGr = profile.warnAmountGr/100 || null
    profile.maxTariffGr = profile.maxTariffGr/100 || null
    if(profile.filters.indexOf(5) !== -1) {
      profile.hybrid = true
    }
    this.setState({ profile: profile, itemPopup: true, action: "edit" })
  }

  saveItem() {
    window.gtag('event', "zarzadzanie_dodaj_profil")
    if (this.state.profile.id) {
      if (this.state.rewrite.id) {
        let profile = {...this.state.profile}
        profile.maxTotalAmountGr ? profile.maxTotalAmountGr = parseFloat((profile.maxTotalAmountGr+" ").replace(",", ".")) : profile.maxTotalAmountGr = null
        profile.warnAmountGr ? profile.warnAmountGr = parseFloat((profile.warnAmountGr+" ").replace(",", ".")) : profile.warnAmountGr = null
        profile.maxTariffGr ? profile.maxTariffGr = parseFloat((profile.maxTariffGr+" ").replace(",", ".")) : profile.maxTariffGr = null

        profile.maxTotalAmountGr = profile.maxTotalAmountGr*100 || null
        profile.warnAmountGr = profile.warnAmountGr*100 || null
        profile.maxTariffGr = profile.maxTariffGr*100 || null

        profile.carClass = profile.carClass ? profile.carClass : null

        if(profile.filters.indexOf(5) === -1 && profile.hybrid === true) {
          profile.filters.push(5)
        } else if(profile.filters.indexOf(5) !== -1 && profile.hybrid === false) {
          const index = profile.filters.indexOf(5);
          if (index > -1) {
            profile.filters.splice(index, 1);
          }
        }

        if (profile.startHour === 0 && profile.startMinute === 0) {
          profile.startHour = null
          profile.startMinute = null
        }
        if (profile.endHour === 0 && profile.endMinute === 0) {
          profile.endHour = null
          profile.endMinute = null
        }
        this.shortFetch("POST", this.endpoint+"/edit", { profile: profile, rewriteTo: this.state.rewrite.id }, () => { 
          this.setState({ itemPopup: false, action: "" })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
        })
      } else {
        let profile = {...this.state.profile}
        profile.maxTotalAmountGr ? profile.maxTotalAmountGr = parseFloat((profile.maxTotalAmountGr+" ").replace(",", ".")) : profile.maxTotalAmountGr = null
        profile.warnAmountGr ? profile.warnAmountGr = parseFloat((profile.warnAmountGr+" ").replace(",", ".")) : profile.warnAmountGr = null
        profile.maxTariffGr ? profile.maxTariffGr = parseFloat((profile.maxTariffGr+" ").replace(",", ".")) : profile.maxTariffGr = null

        profile.maxTotalAmountGr = profile.maxTotalAmountGr*100 || null
        profile.warnAmountGr = profile.warnAmountGr*100 || null
        profile.maxTariffGr = profile.maxTariffGr*100 || null

        profile.carClass = profile.carClass ? profile.carClass : null

        if(profile.filters.indexOf(5) === -1 && profile.hybrid === true) {
          profile.filters.push(5)
        } else if(profile.filters.indexOf(5) !== -1 && profile.hybrid === false) {
          const index = profile.filters.indexOf(5);
          if (index > -1) {
            profile.filters.splice(index, 1);
          }
        }

        if (profile.startHour === 0 && profile.startMinute === 0) {
          profile.startHour = null
          profile.startMinute = null
        }
        if (profile.endHour === 0 && profile.endMinute === 0) {
          profile.endHour = null
          profile.endMinute = null
        }
        this.shortFetch("POST", this.endpoint+"/edit", { profile: profile }, () => { 
          this.setState({ itemPopup: false, action: "" })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
        })
      }  
    } else {
      let profile = {...this.state.profile}
      profile.maxTotalAmountGr ? profile.maxTotalAmountGr = parseFloat((profile.maxTotalAmountGr+" ").replace(",", ".")) : profile.maxTotalAmountGr = null
      profile.warnAmountGr ? profile.warnAmountGr = parseFloat((profile.warnAmountGr+" ").replace(",", ".")) : profile.warnAmountGr = null
      profile.maxTariffGr ? profile.maxTariffGr = parseFloat((profile.maxTariffGr+" ").replace(",", ".")) : profile.maxTariffGr = null

      profile.maxTotalAmountGr = profile.maxTotalAmountGr*100 || null
      profile.warnAmountGr = profile.warnAmountGr*100 || null
      profile.maxTariffGr = profile.maxTariffGr*100 || null

      profile.carClass = profile.carClass ? profile.carClass : null

      if(profile.filters.indexOf(5) === -1 && profile.hybrid === true) {
        profile.filters.push(5)
      } else if(profile.filters.indexOf(5) !== -1 && profile.hybrid === false) {
        const index = profile.filters.indexOf(5);
        if (index > -1) {
          profile.filters.splice(index, 1);
        }
      }

      if (profile.startHour === 0 && profile.startMinute === 0) {
          profile.startHour = null
          profile.startMinute = null
        }
        if (profile.endHour === 0 && profile.endMinute === 0) {
          profile.endHour = null
          profile.endMinute = null
        }
      this.shortFetch("POST", this.endpoint+"/add", { profile: profile }, () => { 
        this.setState({ itemPopup: false, action: "" })
        this.listItems()
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
      })
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.shortFetch("POST", this.endpoint+"/delete", { id: this.state.id, rewriteTo: this.state.rewrite.id }, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete", { id: this.state.id }, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems()
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeValue(item) {
    this.setState({ rewrite: item })
  }

  render () {
    let startDate = new Date()
    startDate.setHours(this.state.profile.startHour !== undefined ? this.state.profile.startHour : 0)
    startDate.setMinutes(this.state.profile.startMinute !== undefined ? this.state.profile.startMinute : 0)

    let endDate = new Date()
    endDate.setHours(this.state.profile.endHour !== undefined ? this.state.profile.endHour : 0)
    endDate.setMinutes(this.state.profile.endMinute !== undefined ? this.state.profile.endMinute : 0)

    const page = this.props.data.manage

    let Popup = null

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.profile.confirmRewrite : page.profile.confirm }</strong></p>
            { this.state.action === "delete_rewrite" ? <div className="input">
              <label>{ page.profile.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={"Wpisz..."} id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else {
      let selectedProfile = _.filter(this.state.data, { id: this.state.profile.id })[0] || {}
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label htmlFor="name">{ page.profile.name }</label>
              <input type="text" name="name" id="name" value={ this.state.profile.name } onChange={ this.handleInputChange.bind(null, "profile") } required/>
            </div>
            <div className="input">
              <label>{ page.profile.hourLimit }</label>
              <div className="flex">
                <div>
                  <label>{ page.profile.from }</label>
                  <DatePicker
                      selected={startDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let profile = this.state.profile
                          profile.startHour = hours
                          profile.startMinute = minutes
                          this.setState({ profile: profile })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
                <div>
                  <label>{ page.profile.to }</label>
                  <DatePicker
                      selected={endDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let profile = this.state.profile
                          profile.endHour = hours
                          profile.endMinute = minutes
                          this.setState({ profile: profile })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
              </div>
            </div>
            <div className="input">
              <label htmlFor="maxOrders">{ page.profile.maxOrders }</label>
              <input type="text" pattern="^[0-9]*$" min="0" name="maxOrders" id="maxOrders" value={ this.state.profile.maxOrders } onChange={ this.handleInputChange.bind(null, "profile") } required/>
            </div>
            <div className="input">
              <label htmlFor="maxTotalAmountGr">{ page.profile.maxTotalAmountGr }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="maxTotalAmountGr" id="maxTotalAmountGr" value={ this.state.profile.maxTotalAmountGr } onChange={ this.handleInputChange.bind(null, "profile") } required/>
            </div>
            <div className="input">
              <label htmlFor="warnAmountGr">{ page.profile.warnAmountGr }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="warnAmountGr" id="warnAmountGr" value={ this.state.profile.warnAmountGr } onChange={ this.handleInputChange.bind(null, "profile") } required/>
            </div>
            <div className="input">
              <label htmlFor="orderDays">{ page.profile.orderDays }</label>
              <select name="orderDays" id="orderDays" value={ this.state.profile.orderDays } onChange={ this.handleInputChange.bind(null, "profile") }>
                <option value="">{ page.select }</option>
                <option value="MONDAY_FRIDAY">PON-PIA</option>
                <option value="MONDAY_SATURDAY">PON-SOB</option>
              </select>
            </div>
            <div className="input">
              <label htmlFor="maxTariffGr">{ page.profile.maxTariffGr }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="maxTariffGr" id="maxTariffGr" value={ this.state.profile.maxTariffGr } onChange={ this.handleInputChange.bind(null, "profile") } required/>
            </div>
            <div className="input">
              <label htmlFor="carClass">{ page.profile.carClass }</label>
              <select name="carClass" id="carClass" value={ this.state.profile.carClass } onChange={ this.handleInputChange.bind(null, "profile") }>
                <option value="">{ page.select }</option>
                { this.state.carClassMap.map((item, key) => {
                  return <option key={key} value={ item.id }>{ item.name }</option>
                }) }
              </select>
            </div>
            <div className="input">
              <div className="check-box">
                <input name="hybrid" id="cb2" type="checkbox" checked={ this.state.profile.hybrid } onChange={ this.handleInputChange.bind(null, "profile") }/>
                <label htmlFor="cb2">{ page.profile.hybrid }</label>
              </div>
            </div>
            <div className="input">
              <label htmlFor="commentForCC">{ page.profile.commentForCC }</label>
              <textarea type="text" name="commentForCC" id="commentForCC" value={ this.state.profile.commentForCC } onChange={ this.handleInputChange.bind(null, "profile") } required></textarea>
            </div>
            { this.state.action === "edit" && selectedProfile.assignUserNr > 0 ? <div className="input">
              <p><strong>{ page.profile.rewriteHead }</strong></p>
              <label>{ page.profile.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={ page.type } id={ this.state.profile.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    return (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/manage/mpk' : '/en/manage/mpk' }>{ page.mpkName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/profiles' : '/en/manage/profiles' }>{ page.profilesName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/projects' : '/en/manage/projects' }>{ page.projectsName }</NavLink>
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.profile.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small violet" onClick={ this.addItem }><span>{ page.add }</span></button>
              </div>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={[
              {
                Header: "ID",
                accessor: 'id',
                show: false
              },
              {
                Header: page.profile.name,
                accessor: "name"
              },
              {
                Header: page.profile.hourLimit,
                accessor: "startHour",
                headerClassName: "no-search",
                minWidth: 120,
                Filter: row => <div></div>,
                Cell: row => {
                  let startHours = ""
                  let endHours = ""
                  if (row.original.startHour !== undefined) {
                    if (row.original.startHour.toString().length === 1) {
                      startHours += "0"+row.original.startHour.toString()
                    } else {
                      startHours += row.original.startHour.toString()
                    }

                    if (row.original.startMinute !== undefined) {
                      if (row.original.startMinute.toString().length === 1) {
                        startHours += ":0"+row.original.startMinute.toString()
                      } else {
                        startHours += ":"+row.original.startMinute.toString()
                      }
                    } else {
                      startHours += ":00"
                    }
                  }

                  if (row.original.endHour !== undefined) {
                    if (row.original.endHour.toString().length === 1) {
                      endHours += "0"+row.original.endHour.toString()
                    } else {
                      endHours += row.original.endHour.toString()
                    }

                    if (row.original.endMinute !== undefined) {
                      if (row.original.endMinute.toString().length === 1) {
                        endHours += ":0"+row.original.endMinute.toString()
                      } else {
                        endHours += ":"+row.original.endMinute.toString()
                      }
                    } else {
                      endHours += ":00"
                    }
                  }
                  return <span>{ startHours+(row.original.startHour !== undefined && row.original.endHour !== undefined ? " - " : "")+endHours }</span>
                },
              },
              {
                Header: page.profile.orderDays,
                accessor: "orderDays",
                Cell: row => <span>{ this.days[row.original.orderDays] }</span>,
                headerClassName: "no-search",
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  } else {
                    return row[filter.id] === filter.value;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    <option value="MONDAY_FRIDAY">PON-PIA</option>
                    <option value="MONDAY_SATURDAY">PON-SOB</option>
                  </select>
              },
              {
                Header: page.profile.maxOrders,
                accessor: "maxOrders",
                minWidth: 120
              },
              {
                Header: page.profile.maxTotalAmountGr,
                accessor: "maxTotalAmountGr",
                minWidth: 120,
                Cell: row => <span>{ row.value ? (row.value/100).toFixed(2) : "" }</span>
              },
              {
                Header: page.profile.warnAmountGr,
                accessor: "warnAmountGr",
                minWidth: 190,
                Cell: row => <span>{ row.value ? (row.value/100).toFixed(2) : "" }</span>
              },
              {
                Header: page.profile.maxTariffGr,
                accessor: "maxTariffGr",
                minWidth: 140,
                Cell: row => <span>{ row.value ? (row.value/100).toFixed(2) : "" }</span>
              },
              {
                Header: page.profile.userNumber,
                accessor: "assignUserNr",
                minWidth: 150
              },
              {
                Header: page.profile.carClass,
                accessor: "carClass",
                headerClassName: "no-search",
                minWidth: 130,
                Cell: row => <span>{ this.state.carClassMapM[row.original.carClass] }</span>,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  } else {
                    return row[filter.id] === filter.value;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    { this.state.carClassMap.map((item, key) => {
                      return <option key={key} value={ item.id }>{ item.name }</option>
                    }) }
                  </select>
              },
              {
                Header: page.profile.filters,
                accessor: "filters",
                headerClassName: "no-search",
                filterable: false,
                sortable: false,
                minWidth: 130,
                Cell: row => (<span>{ row.original.filters.map((item, i) => { return (<span key={i}>{ page.profile.filtersName[item] }<br/></span>) }) }</span>)
              },
              {
                Header: page.options,
                maxWidth: 180,
                minWidth: 180,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => <div className="options">
                  <span onClick={ () => { this.editItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.edit }</span>
                  <span onClick={ () => { this.deleteItem(row.original.id, row.original.assignUserNr) } }><img src="/assets/img/delete-icon.png" alt=""/>{ page.delete }</span>
                </div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ Popup }</Modal>
        </div>
    )
  }
}
