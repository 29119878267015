import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'

export default class extends BaseComponent {

  constructor(props) {
    super(props)

    if (this.props.location.pathname.indexOf("/error") === -1 ) {
      this.props.history.replace(this.props.lang === "pl" ? "/error/404" : "/en/error/404")
    }
  }

  render () {
    const page = this.props.data.errors

    return (
      <main>
        <section className="login error-page">
          <div className="container error-box">
      			<h1>{ this.props.match.params.error || page.tryLater }</h1>
    				{ this.props.match.params.error === "404" ? <h3 className="error">{ page.notExist }</h3> : <h3 className="error">{ page.working }</h3> }
    				{ this.props.match.params.error === "404" ? <NavLink to={ this.props.lang === "pl" ? "/dashboard" : "/en/dashboard" }>&lt;&nbsp;&nbsp;{ page.back }</NavLink> : <div><NavLink to={ this.props.lang === "pl" ? "/login" : "/en/login" }>{ page.loginAgain }</NavLink><br/><br/>{ page.or }<br/><br/><NavLink to={ this.props.lang === "pl" ? "/dashboard" : "/en/dashboard" }>{ page.backToLast }</NavLink></div> }
  	  	  </div>
        </section>
        <Footer/>
      </main>
    )
  }
}
