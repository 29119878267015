import React from 'react'
import BaseComponent from './BaseComponent'
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import _ from 'lodash'
var elementResizeEvent = require('element-resize-event')

const SelectTable = selectTableHOC(ReactTable);

export default class extends BaseComponent {
  static defaultProps = {
    keyField: "id"
  };

  constructor(props) {
    super(props)
    this.state = { selectAll: false, selection: [], arrow: false, leftArrow: false }
    this._bind('toggleSelection', 'toggleAll', 'isSelected', 'rowFn', 'deselect')
    this.deselect()
    window.addEventListener("DESELECT", (data) => { this.deselect() })
    this._bind('onScroll')
  }

  componentDidMount() {
    let table = document.querySelector("div.rt-table")
    let tableBody = document.querySelector("div.rt-tbody")

    if (table.offsetWidth + table.scrollLeft < tableBody.offsetWidth) {
      this.setState({ arrow: true })
    } else {
      this.setState({ arrow: false })
    }

    if (table.scrollLeft > 0) {
      this.setState({ leftArrow: true })
    } else {
      this.setState({ leftArrow: false })
    }

    table.addEventListener('scroll', this.onScroll)

    elementResizeEvent(table, () => {
      this.onScroll()
    })
  }

  onScroll() {
    let table = document.querySelector("div.rt-table")
    let tableBody = document.querySelector("div.rt-tbody")

    if (table.offsetWidth + table.scrollLeft < tableBody.offsetWidth) {
      this.setState({ arrow: true })
    } else {
      this.setState({ arrow: false })
    }

    if (table.scrollLeft > 0) {
      this.setState({ leftArrow: true })
    } else {
      this.setState({ leftArrow: false })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  deselect() {
    this.setState({ selection: [] })
    this.props.updateSelected([])
  }

  toggleSelection(key, shift, row) {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    
    if (keyIndex >= 0) {      
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {      
      selection.push(key);
    }    
    this.setState({ selection });
    this.props.updateSelected(selection)
  };

  /**
   * Toggle all checkboxes for select table
   */
  toggleAll() {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];

    if (selectAll) {      
      const wrappedInstance = this.checkboxTable.getWrappedInstance();      
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      const activeRecords = _.filter(currentRecords, { active: true })      
      activeRecords.forEach(item => {
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
    this.props.updateSelected(selection)
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected(key) {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn(state, rowInfo, column, instance) {
    //const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {        
                                        
        if (handleOriginal) {
          handleOriginal();
        }
      }
    };
  };

  render () {
    if (this.props.data) {
      let inactive = _.filter(this.props.data, { active: false })
      this.timer = setTimeout(() => {for (var i = 0; i < inactive.length; i++) {
        if(document.getElementById("select-"+inactive[i].id)) document.getElementById("select-"+inactive[i].id).disabled = true
        }
      }, 50)
    }

    return (
      <div className="table select-table">
        <div className={ this.state.leftArrow ? "table-arrow-left show" : "table-arrow-left" } onClick={ () => { let table = document.querySelector("div.rt-table"); table.scrollLeft = 0; } }><span></span></div>
        <SelectTable
          ref={r => (this.checkboxTable = r)}
          toggleSelection={this.toggleSelection}
          selectAll={this.state.selectAll}
          selectType="checkbox"
          toggleAll={this.toggleAll}
          isSelected={this.isSelected}
          loading={ this.props.loading || false }
          keyField="id"
          defaultFilterMethod={(filter, row) => (row[filter.id] !== undefined && row[filter.id] !== null ? row[filter.id].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase()) : -1) !== -1}
          columns={this.props.columns}
          data={this.props.data}
          filterable
          defaultPageSize={10}
          minRows={2}
          className="-striped -highlight"
          previousText={ this.props.lang === "pl" ? 'Poprzednia' : 'Previous'}
          nextText={ this.props.lang === "pl" ? 'Następna' : 'Next'}
          noDataText={ this.props.lang === "pl" ? 'Brak wyników!' : 'No results!'}
          pageText={ this.props.lang === "pl" ? 'Strona' : 'Page'}
          ofText={ this.props.lang === "pl" ? 'z' : 'of'}
          rowsText={ this.props.lang === "pl" ? 'wyników' : 'results'}
          loadingText= { this.props.lang === "pl" ? "Pobieranie danych..." : "Loading..." }
        />
        <div className={ this.state.arrow ? "table-arrow show" : "table-arrow" } onClick={ () => { let table = document.querySelector("div.rt-table"); table.scrollLeft = 2000; } }><span></span></div>
      </div>
    )
  }
}
