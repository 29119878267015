import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Modal from './Modal'
import SearchInput, {createFilter} from 'react-search-input'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { cancel: false, orders: [], searchTerm: "" }
    this._bind('shortFetch', 'update', 'getOrders', 'cancel', 'searchUpdated', 'retryOrder')
    window.addEventListener("UPDATE", (data) => { this.update() })
    window.addEventListener("UPDATE_ORDERS", (data) => { this.update() })
    this.filters = ['carClass', 'fullname', 'projectName', 'comment', 'taxiData', 'address', 'targetAddress', 'status']
  }

  update() {
    this.getOrders()
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else if(result.answer === "IN_PROGRESS") {
        this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 400)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  UNSAFE_componentWillMount() {
    this.getOrders()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.timer2)

    let alerts = JSON.parse(localStorage.getItem("alerts")) || []

    for (var i = 0; i < this.state.orders.length; i++) {
      let single = _.filter(alerts, { id: this.state.orders[i].id })[0] || {}

      if (single.id) {
        let index = alerts.indexOf(single)
        alerts[index].checked = true
      }
    }

    localStorage.setItem("alerts", JSON.stringify(alerts))
    window.dispatchEvent(new CustomEvent("UPDATE_STATUS", { 'detail': { status: true }}), true)
  }

  getOrders() {
    this.shortFetch("GET", "order/history", null, (data) => {
      this.setState({ orders: data.result.orders || [] })
    })
  }

  cancel() {
    this.shortFetch("POST", "order/cancel", { future: this.state.future, orderId: this.state.id }, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Anulowano przejazd"}}), true)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się anulować"}}), true)
      }
      this.setState({ id: null, future: null, cancel: false })
      this.timer2 = setTimeout(() => { this.getOrders() }, 2000)
    })
  }

  retryOrder(id) {
    this.shortFetch("POST", "order/retry", { orderId: id }, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Zamówiono ponownie"}}), true)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się zamówić ponownie"}}), true)
      }
    })
  }

  render () {
    const page = this.props.data.ordered

    let cancelModal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.confirm }</h2>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={ () => { this.cancel(); window.gtag('event', "zamowione_odwolaj_potwierdza") } }><span>{ page.yes }</span></button>
          <button className="btn transparent small" id="button" onClick={ () => { this.setState({ id: null, future: null, cancel: false }) } }><span>{ page.no }</span></button>
        </div>
      </div>
    )

    let filteredList = _.sortBy(this.state.orders.filter(createFilter(this.state.searchTerm, this.filters)), ['expectedArrivalTime'])
    let canceled = []

    filteredList = _.filter(filteredList, (item) => {
      if (item.status !== "Kurs anulowany" && item.status !== "Kurs zakończony niepowodzeniem") {
        return item
      } else {
        canceled.push(item)
      }
    })

    filteredList = filteredList.concat(canceled)

    let alerts = JSON.parse(localStorage.getItem("alerts")) || []

    let ordered = filteredList.map((item, i) => {
      let dd = new Date(item.expectedArrivalTime)
      let hour = this.leadingZero(dd.getHours()) + ":" + this.leadingZero( dd.getMinutes())
      let date = this.parseDate(dd)

      let showBadge = _.filter(alerts, { id: item.id })[0] && _.filter(alerts, { id: item.id })[0].checked ? false : true

      return (
        <li key={ i }>
          <div className="data">
            <div className={ showBadge ? "icon show-badge" : "icon" }>
              <div></div>
              <img src="/assets/img/taxi-icon.png" alt=""/>
            </div>
            <div className="data-inner">
              { item.future ? <h4>{ page.orderTime } { hour }<small>{ date }</small></h4> : <h4>{ page.forNow }</h4> }
              { item.status ? <p className="status">
                <strong>{ item.status }</strong>
                { item.status === "Kurs zakończony niepowodzeniem" ? <span className="alert">
                  <img src="/assets/img/alert.svg" alt="alert icon"/>
                  <div className="alert-box">{ page.alert }</div>
                </span> : null }
              </p> : null }
              <p>{ page.startAddress } { item.address }</p>
              { item.targetAddress ? <p>{ page.destAddress } { item.targetAddress }</p> : null }
              { item.guaranteedPrice ? <p>{page.guarantedLabel + item.guaranteedPrice + page.guarantedLabelCurrency}</p> : <p>{page.taximeterLabel}</p> }
              <p>{ page.project } { item.projectName ? item.projectName : page.null }</p>
              { item.comment ? <p>{ page.comment } { item.comment }</p> : null }
              { item.carClass ? <p>{ page.carClass } { item.carClass }</p> : null }
              { item.estate ? <p>{ page.estate }</p> : null }
              { item.hybrid ? <p>{ page.hybrid }</p> : null }
              { item.taxiData ? <p>{ item.taxiData }</p> : null }
              { item.future ? <p className="driver"><span>{ page.driver }</span>{ true ? <span className="alert">
                  <img src="/assets/img/call.svg" alt="call icon"/>
                  <div className="alert-box">{ page.driverAlert }</div>
                </span> : null }
              </p> : null }
              <div className="who">
                { page.passenger }<br/>
                { !item.businessUserId ? <div><span>{ page.guest } { item.fullname }</span></div> : <div><span className="name">{ item.fullname }</span></div> }
              </div>
            </div>
          </div>
          <div className="buttons">
            { item.future && !item.guaranteedPrice ? <NavLink to={ this.props.lang === "pl" ? "/order/edit/"+item.id : "/en/order/edit/"+item.id } className="btn transparent small" onClick={ () => { window.gtag('event', "zamowione_zmien") } }><span>{ page.change }</span></NavLink> : null }
            { item.cancelable ? <button className="btn yellow small" onClick={ () => { this.setState({ cancel: true, id: item.id, future: item.future }); window.gtag('event', "zamowione_odwolaj") } }><span>{ page.cancel }</span></button> : null }
            { item.toRetry ? <button className="btn yellow small" onClick={ () => { this.retryOrder(item.id); window.gtag('event', "zamowione_zamow_ponownie") } }><span>{ "Zamów ponownie" }</span></button> : null }
          </div>
        </li>
      )
    })

    return (
      <section className="ordered-page">
        <div className="box">
          { this.state.orders.length > 0 ? <div className="search">
            <SearchInput type="text" placeholder={ this.props.lang === "pl" ? "Wyszukaj przejazd..." : "Search for..." } value={ this.state.searchTerm } onChange={ this.searchUpdated }/>
            { this.state.searchTerm !== "" ? <div className="reset" onClick={ (e) => { this.setState({ searchTerm: "" }) } }><img src="/assets/img/delete-icon.png" alt=""/></div> : null }
          </div> : null }
          <h2>{ page.ordered }</h2>
          { this.state.orders.length > 0 && filteredList.length > 0 ? <ul className="ordered-list">
            { ordered }
          </ul> : <ul className="ordered-list"><p><strong>{ this.state.orders.length > filteredList.length ? page.noResults : page.noOrders }</strong></p></ul> }
        </div>
        <Modal open={ this.state.cancel } hidePopup={ () => { this.setState({ cancel: false }) } }>{ cancelModal }</Modal>
      </section>
    )
  }
}
