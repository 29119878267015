import React from 'react'
import BaseComponent from './BaseComponent'
import Select from './Select'
import Modal from './Modal'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { userProfile: false, searchTerm: this._nullTerm, changePassword: false, password: "", newPassword: "", oldPassword: "", oldPasswordError: false, passwordError: false, newPasswordError: false, agreementsPopup: false, agreements: { marketingAgreement: false, partnerMarketingAgreement: false } }
    this._bind('searchUpdated', 'checkPassword', 'processReset', 'handleInputChange', 'onEnterPressed', 'fetchCompany', 'changeCompany', 'fetchAgreements', 'updateAgreements')
    this.KEYS_TO_FILTERS = ['name', 'email']

    this.getCompany = _.debounce(e => {
      if (e.length >= 3) {
        this.fetchCompany(e)
      }
    }, 600);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onEnterPressed)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13 && this.state.changePassword) {
      document.getElementById("button").click()
    }
  }

  searchUpdated(term) {
    if (term === "") {
      term = this._nullTerm
    }
    this.setState({searchTerm: term})
  }

  handleInputChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    this.setState({
      [name]: value
    })

    if (name === "password" && name !== "") {
      this.setState({ passwordError: false })
    }

    if (name === "newPassword" && name !== "") {
      this.setState({ newPasswordError: false })
    }

    if (name === "oldPassword" && name !== "") {
      this.setState({ oldPasswordError: false })
    }
  }

  validatePassword(pass) {
    var re = /^\d{4,30}$/;
    return re.test(pass);
  }

  checkPassword() {
    if (this.state.password === "" || !this.validatePassword(this.state.password)) {
      document.getElementById('password').focus()
      this.setState({ passwordError: true })
    }

    if (this.state.oldPassword === "" || !this.validatePassword(this.state.oldPassword)) {
      document.getElementById('oldPassword').focus()
      this.setState({ oldPasswordError: true })
    }

    if (this.state.newPassword !== this.state.password) {
      document.getElementById('newPassword').focus()
      this.setState({ newPasswordError: true })
    } 

    if ((this.state.password !== "" && this.validatePassword(this.state.password)) && (this.state.oldPassword !== "" && this.validatePassword(this.state.oldPassword)) && (this.state.newPassword !== "" && this.validatePassword(this.state.newPassword)) && this.state.password === this.state.newPassword) {
      this.processReset()
    }
  }

  processReset() {
    // if (this.state.password === this.state.newPassword && this.state.oldPassword !== "" && this.state.oldPassword === "1111") {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zmieniono hasło."}}), true);
    //   this.setState({ changePassword: false, password: "", oldPassword: "", newPassword: "" })
    // } else {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się zmienić hasła."}}), true);
    //   this.setState({ password: "", oldPassword: "", newPassword: "" })
    // }
    let data = { oldPass: this.state.oldPassword, newPass: this.state.newPassword }
    fetch(this._apiBase + "account/passChange", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': localStorage.getItem("accessToken")
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {        
        if (result.answer === "OK") {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.settings.passChanged}}), true);
          this.setState({ changePassword: false, password: "", oldPassword: "", newPassword: "" })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }
          this.setState({ password: "", oldPassword: "", newPassword: "" })
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
          document.getElementById('login').focus()
          this.setState({ password: "", oldPassword: "", newPassword: "" })
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
        this.setState({ password: "", oldPassword: "", newPassword: "" })
      })
  }

  updateAgreements() {
    let data = this.state.agreements
    fetch(this._apiBase + "users/agreement/edit", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': localStorage.getItem("accessToken")
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {        
        if (result.answer === "OK") {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.settings.agreementsUpdated}}), true);
          this.setState({ agreementsPopup: false })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }
          this.setState({ password: "", oldPassword: "", newPassword: "" })
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
          document.getElementById('login').focus()
          this.setState({ password: "", oldPassword: "", newPassword: "" })
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
        this.setState({ password: "", oldPassword: "", newPassword: "" })
      })
  }

  changeCompany(item) {
    if (item.name) { 
      this.props.switchAccount(item)
      this.setState({ userProfile: false }) 
    }
  }

  fetchCompany(company) {
    company = encodeURIComponent(company)
    fetch(this._apiBase + "account/partner?name=" + company + "&limit=10", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      }
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          //success(result)
          let partners = []
          for (var i = 0; i < Object.entries(result.result.partners).length; i++) {
            let item = { id: Object.entries(result.result.partners)[i][0], name: Object.entries(result.result.partners)[i][1] }
            partners.push(item)
          }
          this.setState({ partners: partners })
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var j = 0; j < result.result.errors.length; j++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[j].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  fetchAgreements() {
    fetch(this._apiBase + "users/agreement", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      }
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          //success(result)
          console.log(result)
          this.setState({ agreementsPopup: true, agreements: { marketingAgreement: result.result.marketingAgreement, partnerMarketingAgreement: result.result.partnerMarketingAgreement } })
          //this.setState({ agreements: partners })
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var j = 0; j < result.result.errors.length; j++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[j].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  render () {

    let shortName = ""

    let passwordModal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ this.props.data.header.changePassword }</h2>
          <div className="input">
            <label htmlFor="oldPassword">{ this.props.data.loginPage.oldPassword }</label>
            <input type="password" name="oldPassword" id="oldPassword" value={ this.state.oldPassword } className={ this.state.oldPasswordError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
            { this.state.oldPasswordError ? <p className="error">{ this.props.data.loginPage.oldPasswordError }</p> : null }
          </div>
          <div className="input">
            <label htmlFor="password">{ this.props.data.loginPage.newPassword }</label>
            <input type="password" name="password" id="password" value={ this.state.password } className={ this.state.passwordError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
            { this.state.passwordError ? <p className="error">{ this.props.data.loginPage.newPasswordError }</p> : null }
          </div>
          <div className="input">
            <label htmlFor="newPassword">{ this.props.data.loginPage.repeatPassword }</label>
            <input type="password" name="newPassword" id="newPassword" value={ this.state.newPassword } className={ this.state.newPasswordError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
            { this.state.newPasswordError ? <p className="error">{ this.props.data.loginPage.repeatPasswordError }</p> : null }
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.checkPassword }><span>{ this.props.data.header.changePassword }</span></button>
        </div>
      </div>
    )

    let agreementsModal = (
      <div className="password-modal settings">
        <div className="inputs">
          <h2>{ this.props.data.header.changeAgreements }</h2>
          <div className="input">
            <div className="check-box">
              <input name="marketingAgreement" id="cb1" type="checkbox" value={ this.state.agreements.marketingAgreement } checked={ this.state.agreements.marketingAgreement } onChange={ () => { 
                  let agreements = this.state.agreements
                  if (this.state.agreements.marketingAgreement) {
                    agreements.marketingAgreement = false
                  } else {
                    agreements.marketingAgreement = true
                  }
                  this.setState({ agreements: agreements })
                } }/>
              <label htmlFor="cb1">{ this.props.data.header.marketingAgreement }</label>
            </div>
          </div>
          <div className="input">
            <div className="check-box">
              <input name="partnerMarketingAgreement" id="cb2" type="checkbox" value={ this.state.agreements.partnerMarketingAgreement } checked={ this.state.agreements.partnerMarketingAgreement } onChange={ () => { 
                  let agreements = this.state.agreements
                  if (this.state.agreements.partnerMarketingAgreement) {
                    agreements.partnerMarketingAgreement = false
                  } else {
                    agreements.partnerMarketingAgreement = true
                  }
                  this.setState({ agreements: agreements })
                } }/>
              <label htmlFor="cb2">{ this.props.data.header.partnerMarketingAgreement }</label>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.updateAgreements }><span>{ this.props.data.header.updateAgreements }</span></button>
        </div>
      </div>
    )

    if (this.props.user && this.props.user.firstname) {
      shortName = this.props.user.firstname[0] + this.props.user.lastname[0]
    }

    return (
      <section className="settings-page">
        <div className="box">
          <h2>{ this.props.data.settings.title }</h2>
          <div className="user-info">
            <div className="user-info-inner">
              <div className="avatar">
                <div className="avatar-inner">
                  <strong>{ shortName }</strong>
                </div>
              </div>
              <div className="user-data">
                <p><strong>{ this.props.data.header.userData }</strong></p>
                <p>{ this.props.user.firstname + " " + this.props.user.lastname }</p>
                <p>{ this.props.user.partner ? this.props.user.partner.name : ""  }</p>
                { this.props.user.userType === "SUPER" ? <p><strong>SUPER ADMIN</strong></p> : null }
              </div>
            </div>
            <div className="buttons">
              { this.props.user.userType === "USER" ? <button className="btn transparent small" onClick={ () => { this.fetchAgreements(); window.gtag('event', "ustawienia_zmienzgody") } }><span>{ this.props.data.header.changeAgreements }</span></button> : null }
              <button className="btn transparent small" onClick={ () => { this.setState({ changePassword: true }); window.gtag('event', "ustawienia_zmienhaslo") } }><span>{ this.props.data.header.changePassword }</span></button>
              <button className="btn yellow small" onClick={ () => { this.props.logout(); this.setState({ userProfile: false }); window.gtag('event', "ustawienia_wyloguj") } }><span>{ this.props.data.header.logout }</span></button>
            </div>
          </div>
          <div className="users">
            { this.props.user && (this.props.user.userType === "SUPER" || this.props.user.userType === "ADMIN") ? <div className="other-accounts">
            <h4>{ this.props.data.loginPage.selectPartner }</h4>
            <div className="accounts-search">
              <Select noResults={ this.props.data.table.noResults } data={this.state.partners} selected={this.state.partner} filters={['name']} placeholder={ this.props.data.manage.type } onChange={this.getCompany} changeValue={this.changeCompany} />
            </div>
          </div> : null }
          </div>
        </div>
        <Modal open={ this.state.changePassword } hidePopup={ () => { this.setState({ changePassword: false }) } }>{ passwordModal }</Modal>
        <Modal open={ this.state.agreementsPopup } hidePopup={ () => { this.setState({ agreementsPopup: false }) } }>{ agreementsModal }</Modal>
      </section>
    )
  }
}
