import React from 'react'
import BaseComponent from './BaseComponent'
import PieChart from 'react-minimal-pie-chart'
import { NavLink } from 'react-router-dom'
import ScrollReveal from 'scrollreveal'
var randomColor = require('randomcolor');

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { actualAmounts: {}, actualMPKColors: [], last3MonthMPKColors: [] }
    this._bind('shortFetch', 'getStats', 'getReport', 'shortFetchXLS', 'update', 'onMouseOut', 'onMouseOver')
    this.sr = ScrollReveal({ reset: false, duration: 900 })
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.getStats()
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          if (this.props.lang === "pl") {
            this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
          } else {
            this.props.history.replace("/en/error/"+response.status+"?redirect="+this.props.location.pathname)
          }
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result && result.answer === "OK") {
          success(result)
        } else if (result && result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[i].msg } }), true)
          }
        } else if (result && result.answer === "IN_PROGRESS") {
          this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 400)
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result && result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        if (this.props.lang === "pl") {
          this.props.history.replace("/error?redirect="+this.props.location.pathname)
        } else {
          this.props.history.replace("/en/error?redirect="+this.props.location.pathname)
        }
        //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  shortFetchXLS(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else if (response.status === 204) {
          this.noContent = true
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.dashboard.noReport } }), true)
        } else {
          this.noContent = false
          return response.blob()
        }
      })
      .then((result) => {
        if (!this.noContent) {
          var url = window.URL.createObjectURL(result);
          var a = document.createElement('a');
          a.href = url;
          a.download = "report.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "green", 'content': this.props.data.manage.exportSuccessfully } }), true)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  fallbackRandomGradient() {
    let randomColor1 = '#'+ ('000000' + (Math.random()*0xFFFFFF<<0).toString(16)).slice(-6);
    let randomColor2 = '#'+ ('000000' + (Math.random()*0xFFFFFF<<0).toString(16)).slice(-6);
    return [randomColor1, randomColor2];
  }

  getStats() {
    this.shortFetch("GET", "main", null, (data) => {
      let actualMPKColors = [], last3MonthMPKColors = []
      if (data.answer === "OK") {
        for (var i = 0; i < data.result.actualAmounts.actualAmountPerMpk.length; i++) {
            let color = randomColor({ luminosity: 'dark', count: 2 })
          actualMPKColors.push(color)
        }
        for (var j = 0; j < data.result.actualAmounts.last3MonthsCounterPerMpk.length; j++) {
          let color = randomColor({ luminosity: 'dark', count: 2 })
          last3MonthMPKColors.push(color)
        }
        this.setState({ actualAmounts: data.result.actualAmounts, actualMPKColors: actualMPKColors, last3MonthMPKColors: last3MonthMPKColors })
      }
    })
  }

  UNSAFE_componentWillMount() {
    this.getStats()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  componentDidMount() {
    this.markers = []

    for (let i = 0; i < 25; i++) {
      let item = (
        <li key={i} className="taxi-marker" style={{ left: Math.random() * (100 - 0) + 0 + "%", top: Math.random() * (100 - 0) + 0 + "%" }}><img src="/assets/img/taxi-marker.png" alt="" /></li>
      )
      this.markers.push(item)
    }

    this.sr.reveal(".box-content .box-part", { delay: 400, scale: 0.6, origin: "right", distance: "0px", interval: 100 })
    setTimeout(() => { this.sr.reveal(".taxi-markers .taxi-marker", { delay: 400, scale: 0.6, origin: "right", distance: "0px", interval: 50 }) }, 100)
  }

  getReport() {
    let startMonth = new Date()
    startMonth.setMonth(startMonth.getMonth() - 1, 1)
    let endMonth = new Date()
    endMonth.setMonth(endMonth.getMonth(), 0)

    this.shortFetchXLS("POST", "report/export", { dateFrom: startMonth.getTime(), dateTo: endMonth.getTime() }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "green", 'content': this.props.data.manage.generateSuccessfully } }), true)
    })
  }

  getCoords(elem) {
    var box = elem.getBoundingClientRect();

    return { top: Math.round(box.top), left: Math.round(box.left) };
}

  onMouseOut(event, propsData, index) {
    this.setState({ infoBox: false })
  }

  onMouseOver(event, propsData, index) {
    let coords = this.getCoords(event.target)
    this.setState({ infoBox: true, infoBoxName: (propsData[index].name || this.props.data.dashboard.inactive) + " - " + propsData[index].value, infoBoxLeft: coords.left, infoBoxTop: coords.top })
  }

  render() {
    const page = this.props.data.dashboard

    let dataMockActualMPK = []

    if (this.state.actualAmounts.actualAmountPerMpk) {
      for (var i = 0; i < this.state.actualAmounts.actualAmountPerMpk.length; i++) {
        let item = { name: this.state.actualAmounts.actualAmountPerMpk[i].mpkName, value: this.state.actualAmounts.actualAmountPerMpk[i].amount, color: 'url(' + this.state.actualMPKColors[i][0] + ')' }
        dataMockActualMPK.push(item)
      }
    }

    let dataMockLast3MonthMPK = []

    if (this.state.actualAmounts.last3MonthsCounterPerMpk) {
      for (var j = 0; j < this.state.actualAmounts.last3MonthsCounterPerMpk.length; j++) {
        let item = { name: this.state.actualAmounts.last3MonthsCounterPerMpk[j].mpkName, value: this.state.actualAmounts.last3MonthsCounterPerMpk[j].counter, color: 'url(' + this.state.last3MonthMPKColors[j][0] + ')' }
        dataMockLast3MonthMPK.push(item)
      }
    }

    return (
        <section className="dashboard">
          <div className="box top">
            <ul className="taxi-markers">
              {this.markers}
            </ul>
            <div className="box-inner">
              <h3>{ page.orderTaxi }</h3>
              {this.props.user.userType === "USER" && !this.props.user.extraFilter ? <div className="buttons">
                <NavLink className="btn yellow" to={ this.props.lang === "pl" ? "/order" : "/en/order" } onClick={ () => { window.gtag('event', 'dash_zamawia_dlasiebie') } }><span>{ page.myself }</span></NavLink>
              </div> : <div className="buttons">
                  <NavLink className="btn yellow" to={ this.props.lang === "pl" ? "/order" : "/en/order" } onClick={ () => { window.gtag('event', 'dash_zamawia_pracownicy') } }><span>{ page.employee }</span></NavLink>
                  <NavLink className="btn yellow" to={ this.props.lang === "pl" ? "/order/guest" : "/en/order/guest" } onClick={ () => { window.gtag('event', 'dash_zamawia_goscie') } }><span>{ page.guest }</span></NavLink>
                </div>}
            </div>
          </div>
          <div className="box this-month">
            <div className="box-header">
              <h2>{ page.thisMonth }</h2>
            </div>
            <div className="box-content">
              <div className="box-part">
                <div className="ring">
                  <span>{this.state.actualAmounts.orderNums ? this.state.actualAmounts.orderNums : page.noData}</span>
                </div>
                <p dangerouslySetInnerHTML={{ __html: page.countThisMonth }}></p>
              </div>
              <div className="box-part">
                <div className="ring">
                  <span>{this.state.actualAmounts.totalAmount ? this.state.actualAmounts.totalAmount + " zł" : page.noData}</span>
                </div>
                <p dangerouslySetInnerHTML={{ __html: page.valueThisMonth }}></p>
              </div>
              <div className="box-part">
                <div className="new-report">
                  <ul className="anim-bg">
                    <li><img src="/assets/img/anim/1.png" alt="" /></li>
                    <li><img src="/assets/img/anim/2.png" alt="" /></li>
                    <li><img src="/assets/img/anim/3.png" alt="" /></li>
                    <li><img src="/assets/img/anim/4.png" alt="" /></li>
                    <li><img src="/assets/img/anim/5.png" alt="" /></li>
                    <li><img src="/assets/img/anim/6.png" alt="" /></li>
                    <li><img src="/assets/img/anim/7.png" alt="" /></li>
                    <li><img src="/assets/img/anim/8.png" alt="" /></li>
                  </ul>
                  {this.state.actualAmounts.lastReport ? <p dangerouslySetInnerHTML={{ __html: page.newReport }}></p> : <p dangerouslySetInnerHTML={{ __html: page.newReportSoon }}></p>}
                </div>
                {this.state.actualAmounts.lastReport ? <p>{ page.lastMonthReport }</p> : null}
                {this.state.actualAmounts.lastReport ? <div className="btn-holder">
                  <button className="btn green" onClick={ () => { this.getReport(); window.gtag('event', 'dash_pobierzraport') } }><span>{ page.downloadReport }</span></button>
                </div> : null}
              </div>
            </div>
          </div>
          <div className="box stats">
            <div className="box-header">
              <h2>{ page.stats }</h2>
            </div>
            <div className="box-content">
              <div className="box-part">
                {this.state.actualMPKColors.length > 0 ? <PieChart
                  data={dataMockActualMPK}
                  className="pie-chart"
                  segmentsStyle={{ transition: 'stroke .3s' }}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  injectSvg={() => (
                    <defs>
                      {this.state.actualMPKColors.map((item, i) => {
                        return (
                          <linearGradient id={item[0].replace("#", "")} x1="100%" y1="100%" x2="0%" y2="100%" key={i}>
                            <stop offset="0%" stopColor={item[0]} />
                            <stop offset="100%" stopColor={item[0]} />
                          </linearGradient>
                        )
                      })}
                    </defs>
                  )}
                /> : <div className="ring">
                    <span>{this.state.actualAmounts.totalAmount ? this.state.actualAmounts.totalAmount + " zł" : page.noData}</span>
                  </div>}
                <p>{ page.costsMPK }</p>
                {this.state.actualAmounts.actualAmountPerMpk ? <ul className="legend">
                  {this.state.actualAmounts.actualAmountPerMpk.map((item, i) => {
                    return (
                      <li key={i}><span className="dot" style={{ backgroundColor: this.state.actualMPKColors[i] ? this.state.actualMPKColors[i][0] : "" }}></span>{item.mpkName || page.inactive}</li>
                    )
                  })}
                </ul> : null}
                <NavLink to={ this.props.lang === "pl" ? "/reports/r" : "/en/reports/r" } onClick={ () => { window.gtag('event', 'dash_raportyfaktury') } }>{ page.moreDetails }</NavLink>
              </div>
              <div className="box-part">
                {this.state.last3MonthMPKColors.length > 0 ? <PieChart
                  data={dataMockLast3MonthMPK}
                  className="pie-chart"
                  segmentsStyle={{ transition: 'stroke .3s' }}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  injectSvg={() => (
                    <defs>
                      {this.state.last3MonthMPKColors.map((item, i) => {
                        return (
                          <linearGradient id={item[0].replace("#", "")} x1="100%" y1="100%" x2="0%" y2="100%" key={i}>
                            <stop offset="0%" stopColor={item[0]} />
                            <stop offset="100%" stopColor={item[0]} />
                          </linearGradient>
                        )
                      })}
                    </defs>
                  )}
                /> : <div className="ring">
                    <span>{this.state.actualAmounts.totalAmount ? this.state.actualAmounts.totalAmount + " zł" : page.noData}</span>
                  </div>}
                <p dangerouslySetInnerHTML={{ __html: page.last3MonthsValue }}></p>
                {this.state.actualAmounts.last3MonthsCounterPerMpk ? <ul className="legend">
                  {this.state.actualAmounts.last3MonthsCounterPerMpk.map((item, i) => {
                    return (
                      <li key={i}><span className="dot" style={{ backgroundColor: this.state.last3MonthMPKColors[i] ? this.state.last3MonthMPKColors[i][0] : "" }}></span>{item.mpkName || page.inactive}</li>
                    )
                  })}
                </ul> : null}
                <NavLink to={ this.props.lang === "pl" ? "/reports/r" : "/en/reports/r" } onClick={ () => { window.gtag('event', 'dash_raportyfaktury') } }>{ page.moreDetails }</NavLink>
              </div>
              <div className="box-part">
                <div className="ring">
                  <span>{this.state.actualAmounts.last3MonthAmount ? this.state.actualAmounts.last3MonthAmount + " zł" : page.noData}</span>
                </div>
                <p dangerouslySetInnerHTML={{ __html: page.last3MonthsCount }}></p>
                <NavLink to={ this.props.lang === "pl" ? "/reports/r" : "/en/reports/r" } className="last-a" onClick={ () => { window.gtag('event', 'dash_raportyfaktury') } }>{ page.moreDetails }</NavLink>
              </div>
            </div>
          </div>
          <div className={ this.state.infoBox ? "info-box show" : "info-box" } style={{ left: this.state.infoBoxLeft, top: this.state.infoBoxTop }}><p>{ this.state.infoBoxName }</p></div>
        </section>
    )
  }
}
