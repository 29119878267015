import React from 'react'
import BaseComponent from './BaseComponent'
import MPK from './MPK'
import Projects from './Projects'
import Profiles from './Profiles'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      page: this.props.match.params.page ? this.props.match.params.page : "mpk"
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/manage/mpk" : "/en/manage/mpk")
    }
  }

  render () {
    let pageInner = null

    switch(this.state.page) {
      case 'mpk':
        pageInner = <MPK user={ this.props.user } {...this.props}/>
      break
      case 'profiles':
        pageInner = <Profiles user={ this.props.user } {...this.props}/>
      break
      case 'projects':
        pageInner = <Projects user={ this.props.user } {...this.props}/>
      break
      default:
        pageInner = <MPK user={ this.props.user } {...this.props}/>
      break
    }

    return (
      <section className="users-page">
        { pageInner }
      </section>
    )
  }
}
