import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import _ from 'lodash'
import Modal from './Modal'
import Select from './Select'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
import Cleave from 'cleave.js/react';

registerLocale('pl', pl)

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      cardAction: "",
      user: {}, userS: [], itemPopup: false, id: null, rewrite: {},
      voucher: { activationDate: new Date(), type: "ONE_USE_VOUCHER" },
      cardPopup: false,
      quantity: 1,
      card: {
        creditCardNumber: "",
        month: "1",
        year: "20",
        cvv: "",
        agreement: false
      },
      profiles: [],
      profilesArray: [],
      fullProfiles: [],
      departments: [],
      departmentsArray: [],
      profil: {},
      mpk: {},
      canSeeVip: false,
      canEditVip: false,
      voucherPopup: false,
      voucherTypes: [],
      orderDays: [],
      orderDaysMapArray: [],
      carClassMapArray: [],
      ivrOptions: {},
      preloader: true,
      importPopup: false,
      confirmEmails: false,
      importPreloader: false,
      isGuest: false,
      send: false
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'shortFetchXLS',
      'handleInputChange',
      'deleteSuccess',
      'getProfiles',
      'successGetProfiles',
      'getMPK',
      'successGetMPK',
      'changeProfile',
      'changeMPK',
      'sendLogin',
      'exportUsers',
      'sendCardLink',
      'deleteCard',
      'processDeleteCard',
      'addCard',
      'saveCard',
      'addVoucher',
      'listVouchers',
      'successListVouchers',
      'getProjects',
      'successGetProjects',
      'getUsers',
      'changeMPKVoucher',
      'update',
      'validate',
      'importUserData',
      'shortFetchXLSTemplate',
      'getTemplate',
      'shortFetchFile',
      'importFile'
    )

    this.endpoint = "users"

    this.listItems()
    this.getProfiles()
    this.getProjects()
    this.getMPK()
    this.listVouchers()
    this.getUsers()
  
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ user: {}, itemPopup: false, id: null, rewrite: {}, voucher: {active: true} })
    this.listItems()
    this.getProfiles()
    this.getProjects()
    this.getMPK()
    this.listVouchers()
    this.getUsers()
  }

  validate(fields) {
    document.querySelectorAll("input.input-error").forEach((item) => {
      item.className = ""
    })
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }      
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
      if(field === "personName") { if(document.getElementById("businessUserId")) document.getElementById("businessUserId").className = "input-error" }
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")      
      if(first[0]) first[0].focus()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.showAdd) {
        this.setState({ itemPopup: true })
      }
    }, 500)
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/users/u" : "/en/users/u")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
        setTimeout(() => { this.setState({ importPreloader: false }) })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        setTimeout(() => { this.setState({ importPreloader: false }) })
      } else if(result.answer === "IN_PROGRESS") {
        this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 400)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData.fields && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      setTimeout(() => { this.setState({ importPreloader: false }) })
    })
  }

  shortFetchFile(method, endpoint, data, success) {
    let file = document.getElementById('fileSelect')

    var formData = new FormData()
    formData.append('file', file.files[0])

    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Accept': 'application/json',
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: formData
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
        setTimeout(() => { this.setState({ importPreloader: false }) })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        setTimeout(() => { this.setState({ importPreloader: false }) })
      } else if(result.answer === "IN_PROGRESS") {
        this.timer = setTimeout(() => { this.shortFetch(method, endpoint+"&hash="+result.result.hash, { hash: result.result.hash }, success) }, 400)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData.fields && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      setTimeout(() => { this.setState({ importPreloader: false }) })
    })
  }

  shortFetchXLS(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.blob()
      }
    })
    .then((result) => {
      var url = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = "users.xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove(); 
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.exportSuccessfully }}), true)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  shortFetchXLSTemplate(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.blob()
      }
    })
    .then((result) => {
      var url = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = "users_template.xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove(); 
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.exportSuccessfully }}), true)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {
    if (type && event) {      
      const target = event.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name
      let item = this.state[type]

      if (event.target.pattern) {
        let pattern = new RegExp(event.target.pattern)
        value = pattern.test(event.target.value) ? event.target.value : item[name] || "";
      }

      item[name] = value

      if( event.target.className === "input-error" ) event.target.className = ""

      if (event.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      if(name === "braintree") {
        value = value === "true" ? true : false
      }

      this.setState({
        [type]: item
      })
    } else {      
      const target = type.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name

      if (type.target.pattern) {
        let pattern = new RegExp(type.target.pattern)
        value = pattern.test(type.target.value) ? type.target.value : this.state[name] || "";
      }

      if( type.target.className === "input-error" ) type.target.className = ""

      if (type.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      if(name === "braintree") {
        value = value === "true" ? true : false
      }

      this.setState({
        [name]: value
      })
    }
  }

  getUsers() {
    this.shortFetch("GET", "users/short", null, (data) => {
      let usersMap = []
      for (var i = 0; i < Object.entries(data.result.data).length; i++) {
        let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
        usersMap.push(item)
      }
      this.setState({ users: data.result.data, usersArray: usersMap })
    })
  }

  getProjects() {
    this.shortFetch("GET", "projects/short", null, this.successGetProjects)
  }

  successGetProjects(data) {    
    let projects = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      projects.push(item)
    }
    this.setState({ projects: data.result.data, projectsArray: projects })
  }

  listVouchers() {
    this.shortFetch("POST", "vouchers", {}, this.successListVouchers)
  }

  successListVouchers(data) {    
    let carClassMap = []
    for (var i = 0; i < Object.entries(data.result.carClassMap).length; i++) {
      let item = { id: Object.entries(data.result.carClassMap)[i][0], name: Object.entries(data.result.carClassMap)[i][1] }
      carClassMap.push(item)
    }

    let orderDaysMap = []
    for (var j = 0; j < Object.entries(data.result.orderDaysMap).length; j++) {
      let item = { id: Object.entries(data.result.orderDaysMap)[j][0], name: Object.entries(data.result.orderDaysMap)[j][1] }
      orderDaysMap.push(item)
    }

    this.setState({ vouchers: data.result.vouchers, canDeactivate: data.result.canDeactivate, canEdit: data.result.canEdit, canGenerate: data.result.canGenerate, carClassMap: data.result.carClassMap, carClassMapArray: carClassMap, orderDaysMap: data.result.orderDaysMap, orderDaysMapArray: orderDaysMap, itemPopup: false })
  }

  listItems() {
    this.setState({ preloader: true })
    this.shortFetch("POST", this.endpoint+"", {}, this.successListItems)
  }

  successListItems(data) {    
    this.setState({ data: data.result.users, canEditVip: data.result.canEditVip, canSeeVip: data.result.canSeeVip, importPopup: false })
    setTimeout(() => { this.setState({ preloader: false }) }, 100)
  }

  getProfiles() {
    this.shortFetch("GET", "profiles/short", null, this.successGetProfiles)
  }

  successGetProfiles(data) {    
    let profiles = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      profiles.push(item)
    }
    this.setState({ profiles: data.result.data, profilesArray: profiles })
  }

  getMPK() {
    this.shortFetch("GET", "mpks/short", null, this.successGetMPK)
  }

  successGetMPK(data) {    
    let departments = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      departments.push(item)
    }
    this.setState({ departments: data.result.data, departmentsArray: departments })
  }

  addItem() {
    this.shortFetch("GET", this.endpoint+"/new", null, (data) => {
      this.setState({ fullProfiles: data.result.profiles, ivrOptions: data.result.ivrOptions || {}, user: data.result.user || {}, canEditVip: data.result.canEditVip, cardSupport: data.result.cardSupport, ivrSupport: data.result.ivrSupport, itemPopup: true, action: "add" })
    })
  }

  addVoucher() {
    this.shortFetch("GET", "vouchers/new", null, (data) => {
      let voucherTypes = []
      for (var i = 0; i < Object.entries(data.result.voucherTypes).length; i++) {
        let item = { id: Object.entries(data.result.voucherTypes)[i][0], name: Object.entries(data.result.voucherTypes)[i][1] }
        voucherTypes.push(item)
      }
      let voucher = data.result.voucher
      voucher.activationDate = new Date()
      voucher.active = true
      voucher.type = "ONE_USE_VOUCHER"
      delete voucher.mobile
      this.setState({ voucher: voucher, voucherTypes: voucherTypes, canGenerateMulti: data.result.canGenerateMulti, maxCounter: data.result.maxCounter, itemPopup: true, action: "add_voucher" })
    })
  }

  addCard() {
    this.setState({ card: {
        creditCardNumber: "",
        month: "1",
        year: "20",
        cvv: "",
        agreement: false
      }, cardPopup: true, cardAction: "add_card" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    this.setState({ fullProfiles: data.result.profiles, ivrOptions: data.result.ivrOptions || {}, user: data.result.user, canEditVip: data.result.canEditVip, cardSupport: data.result.cardSupport, ivrSupport: data.result.ivrSupport, itemPopup: true, action: "edit" })
  }

  saveItem() {
    if (this.state.action === "add_voucher") {
      let voucher = this.state.voucher
      voucher.isGuest = this.state.isGuest
      if (!voucher.type || voucher.type === "") {
        voucher.type = "ONE_USE_VOUCHER"
      }
      if (voucher.email === "") {
        voucher.email = null
      }
      voucher.amountZl = voucher.amountZl ? parseFloat(voucher.amountZl.replace(",", ".")) : null
      voucher.maxTariffGr ? voucher.maxTariffGr = parseFloat((voucher.maxTariffGr+" ").replace(",", ".")) : voucher.maxTariffGr = null
      if(voucher.maxTariffGr !== null) { voucher.maxTariffGr = voucher.maxTariffGr*100 }
      window.gtag('event', "voucher_generowanie")
      this.shortFetch("POST", "vouchers/add", { send: this.state.send, quantity: this.state.quantity, voucher: voucher }, () => { 
        this.setState({ itemPopup: false, action: "", quantity: 1 })
        this.listItems()
        setTimeout(this.listItems, 2000)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
      })
    } else {
      if (this.state.user.id) {
        this.shortFetch("POST", this.endpoint+"/edit", this.state.user, () => { 
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
          // if (this.state.user.cardUser === true && !this.state.user.braintTreeHashId) {
          //   this.setState({ itemPopup: true, action: "edit" })
          //   this.editItem(this.state.user.id)
          // } else {
          //   this.setState({ itemPopup: false, action: "" })
          // }
          this.setState({ itemPopup: false, action: "" })
        })
      } else {
        this.shortFetch("POST", this.endpoint+"/add", this.state.user, () => { 
          this.setState({ itemPopup: false, action: "" })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
          if (this.state.user.cardUser === true && !this.state.user.braintTreeHashId) {
            // this.editItem(this.state.user.id)
          }
        })
      }
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.rewriteItem()
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems()
    this.setState({ action: "", itemPopup: false })
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeProfile(item) {
    let user = this.state.user
    user.profileId = item.id
    this.setState({ profile: item, user: user })
  }

  changeMPK(item) {
    let user = this.state.user
    user.departmentId = item.id
    this.setState({ mpk: item, user: user })
  }

  sendLogin(id) {
    this.shortFetch("GET", "users/send/"+id, null, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.sendLinkSuccessfully }}), true)
    })
  }

  sendCardLink(id) {
    this.shortFetch("GET", "users/card/sendLink/"+id, null, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.sendCardLinkSuccessfully }}), true)
      this.editItem(this.state.user.id)
    })
  }

  exportUsers() {
    this.shortFetchXLS("POST", "users/export", {}, () => {})
  }

  deleteCard(id) {
    this.setState({ cardPopup: true, action: "delete_card", id: id })
  }

  processDeleteCard() {
    this.shortFetch("GET", "users/card/delete/"+this.state.id, null, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
      this.setState({ cardPopup: false, card: {
        creditCardNumber: "",
        month: "1",
        year: "20",
        cvv: "",
        agreement: false
      }, cardAction: "" })
      this.editItem(this.state.user.id)
    })
  }

  saveCard() {
    this.shortFetch("POST", "users/card/add", {
      creditCardNumber: this.state.card.creditCardNumber,
      month: this.state.card.month,
      year: this.state.card.year,
      cvv: this.state.card.cvv,
      agreement: this.state.card.agreement,
      userId: this.state.user.id,
      hash: this.state.user.braintTreeHashId
    }, (data) => {
      this.setState({ cardPopup: false, card: {
        creditCardNumber: "",
        month: "1",
        year: "20",
        cvv: "",
        agreement: false
      }, cardAction: "" })
      this.editItem(this.state.user.id)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.card.cardAddedSuccessfully }}), true)
    })
  }

  changeMPKVoucher(item) {
    let voucher = this.state.voucher
    voucher.departmentId = parseInt(item.id, 10)
    this.setState({ mpk: item, voucher: voucher })
  }

  parseHours(startHour, startMinute, endHour, endMinute, orderDays) {
    let startHours = ""
    let endHours = ""
    let days = ""
    if (startHour !== undefined) {
      if (startHour.toString().length === 1) {
        startHours += "0"+startHour.toString()
      } else {
        startHours += startHour.toString()
      }

      if (startMinute !== undefined) {
        if (startMinute.toString().length === 1) {
          startHours += ":0"+startMinute.toString()
        } else {
          startHours += ":"+startMinute.toString()
        }
      } else {
        startHours += ":00"
      }
    }

    if (endHour !== undefined) {
      if (endHour.toString().length === 1) {
        endHours += "0"+endHour.toString()
      } else {
        endHours += endHour.toString()
      }

      if (endMinute !== undefined) {
        if (endMinute.toString().length === 1) {
          endHours += ":0"+endMinute.toString()
        } else {
          endHours += ":"+endMinute.toString()
        }
      } else {
        endHours += ":00"
      }
    }

    if (orderDays !== undefined) {
      if (orderDays === "MONDAY_FRIDAY") {
        days = "PON-PIA"
      } else if (orderDays === "MONDAY_SATURDAY") {
        days = "PON-SOB"
      }
    }
    return <span>{ startHours+(startHour !== undefined && endHour !== undefined ? " - " : "")+endHours + (days !== "" ? ", " + days : "") }</span>
  }

  importUserData() {
    this.shortFetch("GET", "users/"+this.state.voucher.businessUserId, null, (data) => {
      let voucher = this.state.voucher
      voucher.departmentId = parseInt(data.result.user.departmentId, 10)
      voucher.email = data.result.user.email
      this.setState({ voucher: voucher })
      if(data.result.user.profileId) {
        this.shortFetch("GET", "profiles/"+data.result.user.profileId, null, (data) => {          
          voucher.carClass = data.result.profile.carClass
          voucher.startHour = data.result.profile.startHour
          voucher.endHour = data.result.profile.endHour
          voucher.startMinute = data.result.profile.startMinute
          voucher.endMinute = data.result.profile.endMinute
          voucher.orderDays = data.result.profile.orderDays
          voucher.amountZl = data.result.profile.maxTotalAmountGr/100 || ""
          voucher.maxTariffGr = data.result.profile.maxTariffGr
          this.setState({ voucher: voucher })
        })
      }
    })
  }

  getTemplate() {
    this.shortFetchXLSTemplate("GET", "users/importFile", null, (data) => {
      //console.log(data)
    })
  }

  importFile() {
    this.shortFetchFile("POST", "users/import?send="+this.state.confirmEmails, null, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zaimportowano" }}), true)
      this.setState({ importPopup: false })
      this.listTimer = setTimeout(() => { this.listItems() }, 1000)
    })
  }

  render () {
    const page = this.props.data.manage

    let Popup = null, cardPopup = null

    let selectedProfile = _.filter(this.state.fullProfiles, { id: parseInt(this.state.user.profileId, 10) })[0]

    let startDate = new Date()
    startDate.setHours(this.state.voucher.startHour !== undefined ? this.state.voucher.startHour : 0)
    startDate.setMinutes(this.state.voucher.startMinute !== undefined ? this.state.voucher.startMinute : 0)

    let endDate = new Date()
    endDate.setHours(this.state.voucher.endHour !== undefined ? this.state.voucher.endHour : 0)
    endDate.setMinutes(this.state.voucher.endMinute !== undefined ? this.state.voucher.endMinute : 0)

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.user.confirmRewrite : page.user.confirm }</strong></p>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else if (this.state.action === "add_voucher") {
      Popup = (
        <div className="password-modal voucher">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.generateVoucherBtn }</h3>
          </div>
          <div className="inputs">
            { this.state.voucher.type !== "MULTI_USE_VOUCHER" && this.state.action !== "edit" ? <div className="input">
              <label htmlFor="quantity">{ page.voucher.quantity }</label>
              <input type="text" pattern="^[0-9]*$" min="1" max={ this.state.maxCounter } name="quantity" id="quantity" value={ this.state.quantity } onChange={ this.handleInputChange } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="type">{ page.voucher.type }</label>
              <select name="type" id="type" value={ this.state.voucher.type } defaultValue={ this.state.voucher.type } onChange={ this.handleInputChange.bind(null, "voucher") }>
                { this.state.voucherTypes.map((item, key) => {
                  return <option key={key} value={ item.id }>{ item.name }</option>
                }) }
              </select>
            </div> : null }
            { this.state.voucher.type === "MULTI_USE_VOUCHER" && this.state.action !== "edit" ? <div className="input">
              <label htmlFor="code">{ page.voucher.code }</label>
              <input type="text" name="code" id="code" value={ this.state.voucher.code } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <div className="check-box"><input name="isGuest" id="cb12" type="checkbox" checked={ !this.state.isGuest } onClick={ () => {
                  let voucher = this.state.voucher
                  voucher.personName = ""
                  this.setState({ isGuest: false, voucher: voucher }) 
                } }/><label htmlFor="cb12">{ page.voucher.businessUser }</label></div>
              <div className="check-box"><input name="isGuest" id="cb13" type="checkbox" checked={ this.state.isGuest } onClick={ () => {
                  let voucher = this.state.voucher
                  voucher.businessUserId = null
                  this.setState({ isGuest: true, voucher: voucher }) 
                } }/><label htmlFor="cb13">{ page.voucher.personName }</label></div>
            </div> : null }
            { this.state.action !== "edit" && !this.state.isGuest ? <div className="input">
              <label htmlFor="businessUser">{ this.props.data.manage.report.nameLastname }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.businessUserId } data={this.state.usersArray} filters={['name']} placeholder={ page.type } changeValue={ (item) => {
                let voucher = this.state.voucher
                voucher.personName = ""
                voucher.businessUserId = parseInt(item.id, 10)
                this.setState({ user: item, voucher: voucher })
              } } id="businessUserId"/>
              { this.state.voucher.businessUserId ? <div className="buttons">
                <button className="btn small yellow" onClick={ this.importUserData }><span>{ page.voucher.import }</span></button>
              </div> : null }
            </div> : null }
            { this.state.action !== "edit" && this.state.isGuest ? <div className="input">
              <label htmlFor="personName">{ this.props.data.manage.report.nameLastname }</label>
              <input type="text" name="personName" id="personName" value={ this.state.voucher.personName } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.departmentId }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.departmentId } data={this.state.departmentsArray} filters={['name']} placeholder={ page.type } changeValue={ this.changeMPKVoucher } id="departmentId"/>
            </div> : null }
            <div className="input">
              <label>{ page.voucher.projectId }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.projectId } data={this.state.projectsArray} filters={['name']} placeholder={ page.type } changeValue={ (item) => {
                let voucher = this.state.voucher
                voucher.projectId = item.id
                this.setState({ project: item, voucher: voucher })
              } } id="projectId"/>
            </div>
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.activationDate }</label>
              <DatePicker
                      selected={this.state.voucher.activationDate}
                      onChange={(date) => {
                        let voucher = this.state.voucher
                        voucher.activationDate = date
                        this.setState({ voucher: voucher })
                      }}
                      locale="pl"
                      dateFormat="dd/MM/yyyy, HH:mm"
                      id="activationDate"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                    />
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.expirationDate }</label>
              <DatePicker
                      selected={this.state.voucher.expirationDate}
                      onChange={(date) => {
                        let voucher = this.state.voucher
                        voucher.expirationDate = date
                        this.setState({ voucher: voucher })
                      }}
                      locale="pl"
                      dateFormat="dd/MM/yyyy, HH:mm"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                    />
            </div> : null }
            <div className="input">
              <label htmlFor="orderDays">{ page.voucher.orderDays }</label>
              <select name="orderDays" id="orderDays" value={ this.state.voucher.orderDays } onChange={ this.handleInputChange.bind(null, "voucher") }>
                <option value="">{ page.select }</option>
                {
                  this.state.orderDaysMapArray.map((item, i) => {
                    return <option key={i} value={ item.id }>{ item.name }</option>
                  })
                }
              </select>
            </div>
            <div className="input">
              <label>{ page.voucher.hourLimit }</label>
              <div className="flex">
                <div>
                  <label>{ page.voucher.from }</label>
                  <DatePicker
                      selected={startDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let voucher = this.state.voucher
                          voucher.startHour = hours
                          voucher.startMinute = minutes
                          this.setState({ voucher: voucher })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
                <div>
                  <label>{ page.voucher.to }</label>
                  <DatePicker
                      selected={endDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let voucher = this.state.voucher
                          voucher.endHour = hours
                          voucher.endMinute = minutes
                          this.setState({ voucher: voucher })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
              </div>
            </div>
            <div className="input">
              <label htmlFor="amountZl">{ page.voucher.amountZl }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="amountZl" id="amountZl" value={ this.state.voucher.amountZl } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div>
            <div className="input">
              <label htmlFor="maxTariffGr">{ page.voucher.maxTariffGr }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="maxTariffGr" id="maxTariffGr" value={ this.state.voucher.maxTariffGr } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div>
            <div className="input">
              <label htmlFor="carClass">{ page.voucher.carClass }</label>
              <select name="carClass" id="carClass" value={ this.state.voucher.carClass } onChange={ this.handleInputChange.bind(null, "voucher") }>
                <option value="">{ page.select }</option>
                { this.state.carClassMapArray.map((item, key) => {
                  return <option key={key} value={ item.id }>{ item.name }</option>
                }) }
              </select>
            </div>
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="email">{ page.voucher.email }</label>
              <input type="text" pattern="^\S*$" name="email" id="email" value={ this.state.voucher.email } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { /* this.state.action !== "edit" ? <div className="input">
              <div className="check-box"><input name="active" id="cb1" type="checkbox" checked={ this.state.voucher.active } onChange={ this.handleInputChange.bind(null, "voucher") }/><label htmlFor="cb1">{ page.voucher.active }</label></div>
            </div> : null */ }
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="orderComment">{ page.voucher.orderComment }</label>
              <textarea name="orderComment" id="orderComment" value={ this.state.voucher.orderComment } onChange={ this.handleInputChange.bind(null, "voucher") } required></textarea>
            </div> : null }
          </div>
          <div className="buttons vouchers">
            <button className="btn yellow small" id="button" onClick={ () => { this.setState({ send: false }); setTimeout(() => { this.saveItem() }, 200) } }><span>{ this.state.action === "edit" ? page.save : page.voucher.btnGenerate }</span></button>
            { this.state.action !== "edit" && this.state.voucher.email && this.state.voucher.email !== "" ? <button className="btn violet small" id="button" onClick={ () => { this.setState({ send: true }); setTimeout(() => { this.saveItem() }, 200) } }><span>{ page.voucher.btnGenerateAndSend }</span></button> : null }
          </div>
        </div>
      )
    } else {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label>{ page.user.profile }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.user.profileId } data={this.state.profilesArray} filters={['name']} placeholder={ page.type } changeValue={ this.changeProfile } id="profileId"/>
              { selectedProfile ? <ul className="profile-info">
                <li>Limit ilościowy: { selectedProfile.maxOrders ? selectedProfile.maxOrders : "brak"}</li>
                <li>Limit czasowy: { selectedProfile.startHour || selectedProfile.startMinute || selectedProfile.endHour || selectedProfile.endMinute || selectedProfile.orderDays ? this.parseHours(selectedProfile.startHour, selectedProfile.startMinute, selectedProfile.endHour, selectedProfile.endMinute, selectedProfile.orderDays) : "brak"}</li>
                <li>Limit kwotowy: { selectedProfile.maxTotalAmountGr ? parseFloat(selectedProfile.maxTotalAmountGr/100, 10).toFixed(2) + " zł" : "brak"}</li>
                <li>Maksymalna stawka/km: { selectedProfile.maxTariffGr ? parseFloat(selectedProfile.maxTariffGr/100, 10).toFixed(2) + " zł" : "brak"}</li>
              </ul> : null }
            </div>
            <div className="input">
              <label htmlFor="firstname">{ page.user.firstname }</label>
              <input type="text" name="firstname" id="firstname" value={ this.state.user.firstname } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label htmlFor="lastname">{ page.user.lastname }</label>
              <input type="text" name="lastname" id="lastname" value={ this.state.user.lastname } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label htmlFor="phone">{ page.user.phone }</label>
              <input type="text" pattern="^[0-9+-]*$"  name="phone" id="phone" value={ this.state.user.phone } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label htmlFor="secondPhone">{ page.user.secondPhone }</label>
              <input type="text" pattern="^[0-9+-]*$"  name="secondPhone" id="secondPhone" value={ this.state.user.secondPhone } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label htmlFor="email">{ page.user.email }</label>
              <input type="text" pattern="^\S*$" name="email" id="email" value={ this.state.user.email } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label>{ page.user.mpk }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.user.departmentId } data={this.state.departmentsArray} filters={['name']} placeholder={ page.type } changeValue={ this.changeMPK } id="departmentId"/>
            </div>
            { this.state.ivrSupport ? <div className="input">
              <label htmlFor="ivrOrders">{ page.user.ivrOrders }</label>
              <select name="ivrOrders" id="ivrOrders" value={ this.state.user.ivrOrders } onChange={ this.handleInputChange.bind(null, "user") }>
                <option value="">{ this.state.ivrOptions[""] }</option>
                <option value="true">{ this.state.ivrOptions.true }</option>
                <option value="false">{ this.state.ivrOptions.false }</option>
              </select>
            </div> : null }
            <div className="input">
              <label htmlFor="password">{ page.user.password }</label>
              <input type="password" name="password" id="password" value={ this.state.user.password } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              <label htmlFor="passwordRetype">{ page.user.passwordRetype }</label>
              <input type="password" name="passwordRetype" id="passwordRetype" value={ this.state.user.passwordRetype } onChange={ this.handleInputChange.bind(null, "user") } required/>
            </div>
            <div className="input">
              { this.state.canEditVip ? <div className="check-box"><input name="vip" id="cb1" type="checkbox" checked={ this.state.user.vip } onChange={ this.handleInputChange.bind(null, "user") }/><label htmlFor="cb1">{ page.user.vip }</label></div> : null }
              <div className="check-box"><input name="projectable" id="cb2" type="checkbox" checked={ this.state.user.projectable } onChange={ this.handleInputChange.bind(null, "user") }/><label htmlFor="cb2">{ page.user.projectable }</label></div>
              { this.state.cardSupport ? <div className="check-box"><input name="cardUser" id="cb3" type="checkbox" checked={ this.state.user.cardUser || false } onChange={ this.handleInputChange.bind(null, "user") }/><label htmlFor="cb3">{ page.user.needCard }</label></div> : null }
              { this.state.cardSupport && this.props.user.userType === "SUPER" && this.state.user.cardUser && !this.state.user.braintTreeToken && !this.state.user.sendCardLink ? <div>
                <div className="radio-button"><input name="braintree" id="cb4" type="radio" checked={ !this.state.user.braintree ? true : false } onChange={ () => { this.handleInputChange("user", {target: { name: "braintree", value: false }}) } }/><label htmlFor="cb4">{ page.user.cardBM }</label></div>
                <div className="radio-button"><input name="braintree" id="cb5" type="radio" checked={ this.state.user.braintree ? true : false } onChange={ () => { this.handleInputChange("user", {target: { name: "braintree", value: true }}) } }/><label htmlFor="cb5">{ page.user.cardBT }</label></div>
              </div> : null }
              { this.state.user.cardUser ? <p>{page.user.cardInfo}</p> : null }
              { this.state.user.id && this.state.user.cardUser && !this.state.user.braintTreeToken ? <div className="card-info">
                { this.state.user.sendCardLink ? <p dangerouslySetInnerHTML={{ __html: page.card.linkSended + this.parseDate(this.state.user.sendCardLink, true) }}></p> : <p>{ page.card.sendLinkInfo }</p> }
                <div className="buttons">
                  <button className="btn violet vsmall" onClick={ this.sendCardLink.bind(null, this.state.user.id) }><span>{ this.state.user.sendCardLink ? page.card.sendAgain : page.card.sendLink }</span></button>
                  <button className="btn violet vsmall" onClick={ this.addCard }><span>{ page.card.addCard }</span></button>
                </div>
              </div> : null }
              { this.state.user.id && this.state.user.cardUser && this.state.user.braintTreeToken ? <div className="card-info">
                <p>{ page.card.cardAdded } {this.state.user.braintTreeHashId ? "Braintree" : "Blue Media"}</p>
                <div className="buttons">
                  <button className="btn violet vsmall" onClick={ this.deleteCard.bind(null, this.state.user.id) }><span>{ page.card.deleteCard }</span></button>
                </div>
              </div> : null }
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    let years = []

    for (var i = new Date().getYear()-100; i <= new Date().getYear()-100+10; i++) {
      years.push(i)
    }

    if (this.state.action === "delete_card") {
      cardPopup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.card.deleteCardTitle }</h3>
            <p><strong>{ page.card.confirmCard }</strong></p>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteCard }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else {
      cardPopup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.cardAction === "edit_card" ? page.card.editCard : page.card.addCard }</h3>
            <div className="input">
              <label htmlFor="creditCardNumber">{ page.card.creditCardNumber }</label>
              <Cleave name="creditCardNumber"
                options={{creditCard: true}}
                onChange={ this.handleInputChange.bind(null, "card") } />
            </div>
            <div className="input">
              <label>{ page.card.date }</label>
              <div className="flex">
                <div>
                  <label htmlFor="month">{ page.card.month }</label>
                  <select id="month" name="month" value={ this.state.card.month } onChange={ this.handleInputChange.bind(null, "card") }>
                    {
                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => {
                        return (
                          <option key={ i } value={ item }>{ item }</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div>
                  <label htmlFor="year">{ page.card.year }</label>
                  <select id="year" name="year" value={ this.state.card.year } onChange={ this.handleInputChange.bind(null, "card") }>
                    {
                      years.map((item, i) => {
                        return (
                          <option key={ i } value={ item }>{ item }</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="input">
              <label htmlFor="cvv">{ page.card.cvv }</label>
              <input type="text" name="cvv" id="cvv" value={ this.state.card.cvv } onChange={ this.handleInputChange.bind(null, "card") } required/>
            </div>
            <div className="input">
              <div className="check-box"><input name="agreement" id="agreement" type="checkbox" checked={ this.state.card.agreement } onChange={ this.handleInputChange.bind(null, "card") }/><label htmlFor="agreement">{ page.card.agreement }</label></div>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveCard }><span>{ page.save }</span></button>
          </div>
          <p className="disclaimer">{ page.card.disclaimer }</p>
        </div>
      )
    }

    let importPopup = (
      <div className="password-modal">
        <div className="inputs">
          <h3>{ page.importUsers }</h3>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={ this.getTemplate }><span>{ page.downloadTemplate }</span></button>
          <div className="input" style={{ margin: "30px 20px" }}>
            <input id="fileSelect" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          </div>
        </div>
        <div className="input">
          <div className="check-box"><input name="confirmEmails" id="confirmEmails" type="checkbox" checked={ this.state.confirmEmails } onChange={ this.handleInputChange }/><label htmlFor="confirmEmails">{ page.sendNotification }</label></div>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={ this.importFile }><span>{ page.import }</span></button>
        </div>
      </div>
    )

    return (
        <div className="box">
          <div className="generate-voucher">
            <h3>{ page.generateVoucher }</h3>
            <button className="btn green small" onClick={ () => { this.addVoucher(); window.gtag('event', "pracownicy_generujvoucher") } }><span>{ page.generateVoucherBtn }</span></button>
          </div>
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/users/u' : '/en/users/u' }>{ page.usersName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/users/v' : '/en/users/v' }>{ page.vouchersName }</NavLink>
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.user.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small violet" onClick={ this.addItem }><span>{ page.add }</span></button>
                { this.props.user.userType === "SUPER" ? <button className="btn small yellow" onClick={ () => { this.setState({ importPopup: true }); window.gtag('event', "pracownicy_importuj") } }><span>{ page.import }</span></button> : null }
                <button className="btn small yellow" onClick={ () => { this.exportUsers(); window.gtag('event', "pracownicy_eksportuj") } }><span>{ page.export }</span></button>
              </div>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={[
              {
                Header: page.user.firstname,
                accessor: "firstname"
              },
              {
                Header: page.user.lastname,
                accessor: "lastname"
              },
              {
                Header: page.user.email,
                accessor: "email"
              },
              {
                Header: page.user.phone1,
                accessor: "phone"
              },
              {
                Header: page.user.phone2,
                accessor: "secondPhone"
              },
              {
                Header: page.user.profileTitle,
                accessor: "profileId",
                Cell: row => (<span>{ this.state.profiles[row.value] }</span>),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row[filter.id] !== undefined && row[filter.id] !== null) {
                    index = this.state.profiles[row[filter.id]].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: "MPK",
                accessor: "departmentId",
                Cell: row => (<span>{ this.state.departments[row.value] }</span>),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row[filter.id] !== undefined && row[filter.id] !== null) {
                    index = this.state.departments[row[filter.id]].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: "VIP",
                accessor: "vip",
                show:this.state.canSeeVip,
                headerClassName: "no-search",
                Cell: row => (<div style={{ textAlign: "center" }} className="check-box"><input id="cb" type="checkbox" disabled checked={ row.value }/><label htmlFor="cb"></label></div>),
                maxWidth: 80,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id] === true;
                  } else {
                    return row[filter.id] === false;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    <option value="true">{ page.yes }</option>
                    <option value="false">{ page.no }</option>
                  </select>
              },
              {
                Header: page.user.card,
                headerClassName: "no-search",
                Cell: row => (<div><div style={{ textAlign: "center" }} className="check-box"><input id="cb" type="checkbox" disabled checked={ row.original.cardUser && row.original.braintTreeToken }/><label htmlFor="cb"></label></div>{ row.original.deleted ? <span>Nie dodano karty</span> : null }</div>),
                maxWidth: 130,
                minWidth: 130,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  console.log(row)
                  if (filter.value === "true") {
                    return row._original.cardUser;
                  } else if (filter.value === "false"){
                    return !row._original.cardUser;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    <option value="true">{ page.yes }</option>
                    <option value="false">{ page.no }</option>
                  </select>
              },
              {
                Header: page.options,
                maxWidth: 180,
                minWidth: 180,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => <div className="options">
                  <span onClick={ () => { this.sendLogin(row.original.id); window.gtag('event', "pracownicy_wyslijlogin") } }><img src="/assets/img/mail-icon.png" alt=""/>{ page.user.sendLogin }</span>
                  <span onClick={ () => { this.editItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.edit }</span>
                  <span onClick={ () => { this.deleteItem(row.original.id) } }><img src="/assets/img/delete-icon.png" alt=""/>{ page.delete }</span>
                </div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "", quantity: 1 }) }, 500) } }>{ Popup }</Modal>
          <Modal open={ this.state.cardPopup } hidePopup={ () => { this.setState({ cardPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ cardPopup }</Modal>
          <Modal open={ this.state.importPopup } hidePopup={ () => { this.setState({ importPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ importPopup }</Modal>
        </div>
    )
  }
}
