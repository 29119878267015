import React from 'react'
import BaseComponent from './BaseComponent'
import ScrollReveal from 'scrollreveal'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.sr = ScrollReveal({ reset: false, duration: 900 })
  }

  componentDidMount() {
    this.sr.reveal(".box-content .box-part", { delay: 400, scale: 0.6, origin: "right", distance: "0px", interval: 100 })
    setTimeout(() => { this.sr.reveal(".taxi-markers .taxi-marker", { delay: 400, scale: 0.6, origin: "right", distance: "0px", interval: 50 }) }, 100)
  }

  render() {
    const page = this.props.data.ipaczka

    return (
        <section className="order-ipaczka">
            <h2>{ page.title }</h2>
            <div className='content' dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </section>
    )
  }
}
