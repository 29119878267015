import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import SelectTable from './SelectTable'
import Modal from './Modal'
import Select from './Select'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      voucher: { activationDate: new Date(), type: "ONE_USE_VOUCHER" }, voucherS: [], itemPopup: false, id: null, rewrite: {},
      voucherTypes: [],
      maxCounter: 1,
      quantity: 1,
      canGenerateMulti: false,
      carClassMap: {},
      carClassMapArray: [],
      orderDaysMap: {},
      orderDaysMapArray: [],
      projects: [],
      projectsArray: [],
      departments: [],
      departmentsArray: [],
      users: {},
      usersArray: [],
      user: {},
      profil: {},
      mpk: {},
      selected: [],
      markPopup: false,
      sendPopup: false,
      deactivatePopup: false,
      sendEmail: "",
      markEmail: "",
      startGenerate: new Date(),
      endGenerate: new Date(),
      printPopup: false,
      generatePopup: false,
      active: "ALL",
      filter: "ALL",
      preloader: true,
      printPreloader: false,
      isGuest: false,
      send: false
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deactivateItem', 
      'successListItems', 
      'saveItem', 
      'processDeactivateItem', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'shortFetchXLS',
      'handleInputChange',
      'deactivateSuccess',
      'getProjects',
      'successGetProjects',
      'getMPK',
      'successGetMPK',
      'changeProfile',
      'changeMPK',
      'updateSelected',
      'getUsers',
      'importUserData',
      'sendSelected',
      'markSelected',
      'deactivateSelected',
      'checkAction',
      'processSendSelected',
      'processMarkSelected',
      'processDeactivateSelected',
      'processPrint',
      'processGenerate',
      'editComment',
      'saveComment',
      'changeFilter',
      'update',
      'validate',
      'printSelected',
      'shortFetchPDF'
    )

    this.endpoint = "vouchers"

    this.listItems( this.state.filter )
    this.getProjects()
    this.getMPK()
    this.getUsers()
  
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ voucher: { activationDate: new Date() }, itemPopup: false, id: null, rewrite: {}, filter: "ALL", quantity: 1 })
    this.listItems( this.state.filter )
    this.getProjects()
    this.getMPK()
    this.getUsers()
  }

  validate(fields) {
    document.querySelectorAll("input.input-error").forEach((item) => {
      item.className = ""
    })
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
      if(field === "personName") { if(document.getElementById("businessUserId")) document.getElementById("businessUserId").className = "input-error" }
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/users/u" : "/en/users/u")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  shortFetchXLS(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.blob()
      }
    })
    .then((result) => {
      var url = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = "vouchers.xlsx";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove(); 
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie wygenerowano" }}), true)
      this.setState({ generatePopup: false})
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  shortFetchPDF(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.blob()
      }
    })
    .then((result) => {
      var url = window.URL.createObjectURL(result);
      var a = document.createElement('a');
      a.href = url;
      a.download = "vouchers.pdf";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove(); 
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.exportSuccessfully }}), true)
      this.setState({ printPopup: false})
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {
    if (type && event) {      
      const target = event.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name
      let item = this.state[type]

      if (event.target.pattern) {
        let pattern = new RegExp(event.target.pattern)
        value = pattern.test(event.target.value) ? event.target.value : item[name] || "";
      }

      if( event.target.className === "input-error" ) event.target.className = ""

      if (event.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      item[name] = value

      this.setState({
        [type]: item
      })
    } else {      
      const target = type.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name

      if (type.target.pattern) {
        let pattern = new RegExp(type.target.pattern)
        value = pattern.test(type.target.value) ? type.target.value : this.state[name] || "";
      }

      if( type.target.className === "input-error" ) type.target.className = ""

      if (type.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      this.setState({
        [name]: value
      })
    }
  }

  listItems( filter ) {
    this.setState({ preloader: true })
    this.shortFetch("POST", this.endpoint+"", { listType: filter }, this.successListItems)
  }

  successListItems(data) {    
    let carClassMap = []
    for (var i = 0; i < Object.entries(data.result.carClassMap).length; i++) {
      let item = { id: Object.entries(data.result.carClassMap)[i][0], name: Object.entries(data.result.carClassMap)[i][1] }
      carClassMap.push(item)
    }

    let orderDaysMap = []
    for (var j = 0; j < Object.entries(data.result.orderDaysMap).length; j++) {
      let item = { id: Object.entries(data.result.orderDaysMap)[j][0], name: Object.entries(data.result.orderDaysMap)[j][1] }
      orderDaysMap.push(item)
    }

    this.setState({ data: data.result.vouchers, canDeactivate: data.result.canDeactivate, canEdit: data.result.canEdit, canGenerate: data.result.canGenerate, carClassMap: data.result.carClassMap, carClassMapArray: carClassMap, orderDaysMap: data.result.orderDaysMap, orderDaysMapArray: orderDaysMap, itemPopup: false, action: "" })
    setTimeout(() => { this.setState({ preloader: false }) }, 100)
  }

  getProjects() {
    this.shortFetch("GET", "projects/short", null, this.successGetProjects)
  }

  getUsers() {
    this.shortFetch("GET", "users/short", null, (data) => {
      let usersMap = []
      for (var i = 0; i < Object.entries(data.result.data).length; i++) {
        let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
        usersMap.push(item)
      }
      this.setState({ users: data.result.data, usersArray: usersMap })
    })
  }

  successGetProjects(data) {    
    let projects = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      projects.push(item)
    }
    this.setState({ projects: data.result.data, projectsArray: projects })
  }

  getMPK() {
    this.shortFetch("GET", "mpks/short", null, this.successGetMPK)
  }

  successGetMPK(data) {    
    let departments = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      departments.push(item)
    }
    this.setState({ departments: data.result.data, departmentsArray: departments })
  }

  addItem() {
    this.shortFetch("GET", this.endpoint+"/new", null, (data) => {
      let voucherTypes = []
      for (var i = 0; i < Object.entries(data.result.voucherTypes).length; i++) {
        let item = { id: Object.entries(data.result.voucherTypes)[i][0], name: Object.entries(data.result.voucherTypes)[i][1] }
        voucherTypes.push(item)
      }
      let voucher = data.result.voucher
      voucher.activationDate = new Date()
      voucher.active = true
      voucher.type = "ONE_USE_VOUCHER"
      delete voucher.mobile
      this.setState({ voucher: voucher, voucherTypes: voucherTypes, canGenerateMulti: data.result.canGenerateMulti, maxCounter: data.result.maxCounter, itemPopup: true, action: "add" })
    })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    let voucherTypes = []
    for (var i = 0; i < Object.entries(data.result.voucherTypes).length; i++) {
      let item = { id: Object.entries(data.result.voucherTypes)[i][0], name: Object.entries(data.result.voucherTypes)[i][1] }
      voucherTypes.push(item)
    }
    let voucher = data.result.voucher
    voucher.maxTariffGr = voucher.maxTariffGr/100 || null
    this.setState({ voucher: voucher, voucherTypes: voucherTypes, canGenerateMulti: data.result.canGenerateMulti, maxCounter: data.result.maxCounter, itemPopup: true, action: "edit" })
  }

  editComment(id) {
    this.setState({ itemPopup: true, action: "edit_comment", id: id })
  }

  saveComment(data) {
    this.shortFetch("POST", this.endpoint+"/comment", { voucherId: this.state.id, comment: this.state.voucher.comment }, (data) => {
      this.setState({ itemPopup: false })
      setTimeout(this.listItems.bind(null, this.state.filter), 1000)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
    })
  }

  saveItem() {
    if (this.state.voucher.id) {
      let voucher = this.state.voucher
      voucher.isGuest = this.state.isGuest
      if (!voucher.type || voucher.type === "") {
        voucher.type = "ONE_USE_VOUCHER"
      }
      if (voucher.email === "") {
        voucher.email = null
      }
      if (voucher.projectId !== "" || voucher.projectId !== null) {
        voucher.projectId = parseInt(voucher.projectId)
      }

      voucher.amountZl = voucher.amountZl ? parseFloat(voucher.amountZl.toString().replace(",", ".")) : null
      voucher.maxTariffGr ? voucher.maxTariffGr = parseFloat(voucher.maxTariffGr.toString().replace(",", ".")) : voucher.maxTariffGr = null
      if(voucher.maxTariffGr !== null) { voucher.maxTariffGr = voucher.maxTariffGr*100 }
      this.shortFetch("POST", this.endpoint+"/edit", { voucher: voucher, send: this.state.send }, () => { 
        this.setState({ itemPopup: false, action: "", quantity: 1 })
        this.listItems( this.state.filter )
        setTimeout(this.listItems.bind(null, this.state.filter), 2000)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
      })
    } else {
      let voucher = this.state.voucher
      voucher.isGuest = this.state.isGuest
      if (!voucher.type || voucher.type === "") {
        voucher.type = "ONE_USE_VOUCHER"
      }
      if (voucher.email === "") {
        voucher.email = null
      }
      voucher.amountZl = voucher.amountZl ? parseFloat(voucher.amountZl.toString().replace(",", ".")) : null
      voucher.maxTariffGr ? voucher.maxTariffGr = parseFloat(voucher.maxTariffGr.toString().replace(",", ".")) : voucher.maxTariffGr = null
      if(voucher.maxTariffGr !== null) { voucher.maxTariffGr = voucher.maxTariffGr*100 }
      window.gtag('event', "voucher_generowanie")
      this.shortFetch("POST", this.endpoint+"/add", { send: this.state.send, quantity: this.state.quantity, voucher: voucher }, () => { 
        this.setState({ itemPopup: false, action: "", quantity: 1 })
        this.listItems( this.state.filter )
        setTimeout(this.listItems.bind(null, this.state.filter), 2000)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
      })
    }
  }

  deactivateItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeactivateItem() {
    this.shortFetch("GET", this.endpoint+"/deactivate/"+this.state.id, null, this.deactivateSuccess)
  }

  deactivateSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deactivateSuccessfully }}), true)
    this.listItems( this.state.filter )
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeProfile(item) {
    let voucher = this.state.voucher
    voucher.profileId = parseInt(item.id, 10)
    this.setState({ profile: item, voucher: voucher })
  }

  changeMPK(item) {
    let voucher = this.state.voucher
    voucher.departmentId = parseInt(item.id, 10)
    this.setState({ mpk: item, voucher: voucher })
  }

  updateSelected(selection) {
    let selected = []

    for (var i = 0; i < selection.length; i++) {
      selected.push(selection[i].replace("select-", ""))
    }
    this.setState({ selected: selected })
  }

  importUserData() {
    this.shortFetch("GET", "users/"+this.state.voucher.businessUserId, null, (data) => {
      let voucher = this.state.voucher
      voucher.departmentId = parseInt(data.result.user.departmentId, 10)
      voucher.email = data.result.user.email
      this.setState({ voucher: voucher })
      if(data.result.user.profileId) {
        this.shortFetch("GET", "profiles/"+data.result.user.profileId, null, (data) => {          
          voucher.carClass = data.result.profile.carClass
          voucher.startHour = data.result.profile.startHour
          voucher.endHour = data.result.profile.endHour
          voucher.startMinute = data.result.profile.startMinute
          voucher.endMinute = data.result.profile.endMinute
          voucher.orderDays = data.result.profile.orderDays
          voucher.amountZl = data.result.profile.maxTotalAmountGr/100 || ""
          voucher.maxTariffGr = data.result.profile.maxTariffGr
          this.setState({ voucher: voucher })
        })
      }
    })
  }

  checkAction(e) {
    if (e.target.value === "sendSelected") {
      this.sendSelected()
    } else if (e.target.value === "markSelected") {
      this.markSelected()
    } else if (e.target.value === "deactivateSelected") {
      this.deactivateSelected()
    }
    e.target.value = ""
  }

  changeFilter(e) {
    this.setState({ filter: e.target.value })
    this.listItems( e.target.value )
  }

  sendSelected() {
    if (this.state.selected.length > 0) {
      this.setState({ sendPopup: true })
    } else {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.notSelected }}), true)
    }
  }

  markSelected() {
    if (this.state.selected.length > 0) {
      this.setState({ markPopup: true })
    } else {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.notSelected }}), true)
    }
  }

  deactivateSelected() {
    if (this.state.selected.length > 0) {
      this.setState({ deactivatePopup: true })
    } else {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.notSelected }}), true)
    }
  }

  printSelected() {
    if (this.state.selected.length > 0) {
      this.setState({ printPopup: true })
    } else {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.manage.notSelected }}), true)
    }
  }

  processSendSelected() {
    this.setState({ printPreloader: true })
    this.shortFetch("POST", this.endpoint+"/send", { sizePerPage: 3, email: this.state.sendEmail, vouchers: this.state.selected, lang: this.props.lang.toUpperCase() }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.sendSuccessfully }}), true)
      this.setState({ sendPopup: false})
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
      this.listItems( this.state.filter )
      window.dispatchEvent(new CustomEvent("DESELECT", { 'detail': {}}), true)
    })
  }

  processMarkSelected() {
    this.setState({ printPreloader: true })
    this.shortFetch("POST", this.endpoint+"/mark", { email: this.state.markEmail, vouchers: this.state.selected }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.markSuccessfully }}), true)
      this.setState({ markPopup: false})
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
      this.listItems( this.state.filter )
      window.dispatchEvent(new CustomEvent("DESELECT", { 'detail': {}}), true)
    })
  }

  processDeactivateSelected() {
    this.setState({ printPreloader: true })
    this.shortFetch("POST", this.endpoint+"/deactivate", { vouchers: this.state.selected }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deactivateSuccessfully }}), true)
      this.setState({ deactivatePopup: false})
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
      this.listItems( this.state.filter )
      window.dispatchEvent(new CustomEvent("DESELECT", { 'detail': {}}), true)
    })
  }

  processPrint() {
    this.setState({ printPreloader: true })
    this.shortFetchPDF("POST", this.endpoint+"/pdf", { sizePerPage: 3, email: this.state.sendEmail, vouchers: this.state.selected, lang: this.props.lang.toUpperCase() }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Wygenerowano vouchery" }}), true)
      this.setState({ printPopup: false })
      //window.dispatchEvent(new CustomEvent("DESELECT", { 'detail': {}}), true)
    })
  }

  processGenerate() {
    this.setState({ printPreloader: true })
    this.shortFetchXLS("POST", this.endpoint+"/export", { dateFrom: this.state.startGenerate.getTime(), dateTo: this.state.endGenerate.getTime(), listType: this.state.active }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.generateSuccessfully }}), true)
      this.setState({ generatePopup: false })
    })
  }

  render () {    
    const page = this.props.data.manage

    let startDate = new Date()
    startDate.setHours(this.state.voucher.startHour !== undefined ? this.state.voucher.startHour : 0)
    startDate.setMinutes(this.state.voucher.startMinute !== undefined ? this.state.voucher.startMinute : 0)

    let endDate = new Date()
    endDate.setHours(this.state.voucher.endHour !== undefined ? this.state.voucher.endHour : 0)
    endDate.setMinutes(this.state.voucher.endMinute !== undefined ? this.state.voucher.endMinute : 0)

    let Popup = null

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.voucher.deactivateTitle }</h3>
            <p><strong>{ page.voucher.deactivateConfirm }</strong></p>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeactivateItem }><span>{ page.voucher.deactivate }</span></button>
          </div>
        </div>
      )
    } else if (this.state.action === "edit_comment") {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ page.addComment }</h3>
          </div>
          <div className="inputs">
            <div className="input">
              <label htmlFor="comment">{ page.voucher.comment }</label>
              <textarea name="comment" id="comment" value={ this.state.voucher.comment } onChange={ this.handleInputChange.bind(null, "voucher") } required></textarea>
            </div>
          </div>
          <div className="buttons vouchers">
            <button className="btn yellow small" id="button" onClick={ this.saveComment }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    } else {
      Popup = (
        <div className="password-modal voucher">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.generateVoucherBtn }</h3>
          </div>
          <div className="inputs">
            { this.state.voucher.type !== "MULTI_USE_VOUCHER" && this.state.action !== "edit" ? <div className="input">
              <label htmlFor="quantity">{ page.voucher.quantity }</label>
              <input type="text" pattern="^[0-9]*$" min="1" max={ this.state.maxCounter } name="quantity" id="quantity" value={ this.state.quantity } onChange={ this.handleInputChange } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="type">{ page.voucher.type }</label>
              <select name="type" id="type" value={ this.state.voucher.type } defaultValue={ this.state.voucher.type } onChange={ this.handleInputChange.bind(null, "voucher") }>
                { this.state.voucherTypes.map((item, key) => {
                  return <option key={key} value={ item.id }>{ item.name }</option>
                }) }
              </select>
            </div> : null }
            { this.state.voucher.type === "MULTI_USE_VOUCHER" && this.state.action !== "edit" ? <div className="input">
              <label htmlFor="code">{ page.voucher.code }</label>
              <input type="text" name="code" id="code" value={ this.state.voucher.code } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <div className="check-box"><input name="isGuest" id="cb12" type="checkbox" checked={ !this.state.isGuest } onClick={ () => {
                  let voucher = this.state.voucher
                  voucher.personName = ""
                  this.setState({ isGuest: false, voucher: voucher }) 
                } }/><label htmlFor="cb12">{ page.voucher.businessUser }</label></div>
              <div className="check-box"><input name="isGuest" id="cb13" type="checkbox" checked={ this.state.isGuest } onClick={ () => {
                  let voucher = this.state.voucher
                  voucher.businessUserId = null
                  this.setState({ isGuest: true, voucher: voucher }) 
                } }/><label htmlFor="cb13">{ page.voucher.personName }</label></div>
            </div> : null }
            { this.state.action !== "edit" && !this.state.isGuest ? <div className="input">
              <label htmlFor="businessUser">{ this.props.data.manage.report.nameLastname }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.businessUserId } data={this.state.usersArray} filters={['name']} placeholder={ page.type } changeValue={ (item) => {
                let voucher = this.state.voucher
                voucher.personName = ""
                voucher.businessUserId = parseInt(item.id, 10)
                this.setState({ user: item, voucher: voucher })
              } } id="businessUserId"/>
              { this.state.voucher.businessUserId ? <div className="buttons">
                <button className="btn small yellow" onClick={ this.importUserData }><span>{ page.voucher.import }</span></button>
              </div> : null }
            </div> : null }
            { this.state.action !== "edit" && this.state.isGuest ? <div className="input">
              <label htmlFor="personName">{ this.props.data.manage.report.nameLastname }</label>
              <input type="text" name="personName" id="personName" value={ this.state.voucher.personName } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.departmentId }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.departmentId } data={this.state.departmentsArray} filters={['name']} placeholder={ page.type } changeValue={ this.changeMPK } id="departmentId"/>
            </div> : null }
            <div className="input">
              <label>{ page.voucher.projectId }</label>
              <Select noResults={ this.props.data.table.noResults } selected={ this.state.voucher.projectId } data={this.state.projectsArray} filters={['name']} placeholder={ page.type } changeValue={ (item) => {
                let voucher = this.state.voucher
                voucher.projectId = item.id
                this.setState({ project: item, voucher: voucher })
              } } id="projectId"/>
            </div>
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.activationDate }</label>
              <DatePicker
                      selected={this.state.voucher.activationDate}
                      onChange={(date) => {
                        let voucher = this.state.voucher
                        voucher.activationDate = date
                        this.setState({ voucher: voucher })
                      }}
                      locale="pl"
                      dateFormat="dd/MM/yyyy, HH:mm"
                      id="activationDate"
                      type="search"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                    />
            </div> : null }
            { this.state.action !== "edit" ? <div className="input">
              <label>{ page.voucher.expirationDate }</label>
              <DatePicker
                      selected={this.state.voucher.expirationDate}
                      onChange={(date) => {
                        let voucher = this.state.voucher
                        voucher.expirationDate = date
                        this.setState({ voucher: voucher })
                      }}
                      locale="pl"
                      dateFormat="dd/MM/yyyy, HH:mm"
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                    />
            </div> : null }
            <div className="input">
              <label htmlFor="orderDays">{ page.voucher.orderDays }</label>
              <select name="orderDays" id="orderDays" value={ this.state.voucher.orderDays } onChange={ this.handleInputChange.bind(null, "voucher") }>
                <option value="">{ page.select }</option>
                {
                  this.state.orderDaysMapArray.map((item, i) => {
                    return <option key={i} value={ item.id }>{ item.name }</option>
                  })
                }
              </select>
            </div>
            <div className="input">
              <label>{ page.voucher.hourLimit }</label>
              <div className="flex">
                <div>
                  <label>{ page.voucher.from }</label>
                  <DatePicker
                      selected={startDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let voucher = this.state.voucher
                          voucher.startHour = hours
                          voucher.startMinute = minutes
                          this.setState({ voucher: voucher })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
                <div>
                  <label>{ page.voucher.to }</label>
                  <DatePicker
                      selected={endDate}
                      onChangeRaw={ (e) => { 
                        let pattern = new RegExp("^[0-9:]*$"); 
                        if(!pattern.test(e.target.value)) { e.preventDefault() } 
                        }
                      }
                      onChange={ (date) => {
                        if (date) {
                          let hours = date.getHours()
                          let minutes = date.getMinutes()
                          let voucher = this.state.voucher
                          voucher.endHour = hours
                          voucher.endMinute = minutes
                          this.setState({ voucher: voucher })
                        }
                      } }
                      showTimeSelect
                      showTimeSelectOnly
                      locale="pl"
                      dateFormat="HH:mm"
                      timeFormat="p"
                      timeIntervals={30}
                      timeCaption="Godzina"
                    />
                </div>
              </div>
            </div>
            <div className="input">
              <label htmlFor="amountZl">{ page.voucher.amountZl }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="amountZl" id="amountZl" value={ this.state.voucher.amountZl } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div>
            <div className="input">
              <label htmlFor="maxTariffGr">{ page.voucher.maxTariffGr }</label>
              <input type="text" pattern="^[0-9.,]*$" min="0" name="maxTariffGr" id="maxTariffGr" value={ this.state.voucher.maxTariffGr } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div>
            <div className="input">
              <label htmlFor="carClass">{ page.voucher.carClass }</label>
              <select name="carClass" id="carClass" value={ this.state.voucher.carClass } onChange={ this.handleInputChange.bind(null, "voucher") }>
                <option value="">{ page.select }</option>
                { this.state.carClassMapArray.map((item, key) => {
                  return <option key={key} value={ item.id }>{ item.name }</option>
                }) }
              </select>
            </div>
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="email">{ page.voucher.email }</label>
              <input type="text" pattern="^\S*$" name="email" id="email" value={ this.state.voucher.email } onChange={ this.handleInputChange.bind(null, "voucher") } required/>
            </div> : null }
            { /* this.state.action !== "edit" ? <div className="input">
              <div className="check-box"><input name="active" id="cb1" type="checkbox" checked={ this.state.voucher.active } onChange={ this.handleInputChange.bind(null, "voucher") }/><label htmlFor="cb1">{ page.voucher.active }</label></div>
            </div> : null */ }
            { this.state.action !== "edit" ? <div className="input">
              <label htmlFor="orderComment">{ page.voucher.orderComment }</label>
              <textarea name="orderComment" id="orderComment" value={ this.state.voucher.orderComment } onChange={ this.handleInputChange.bind(null, "voucher") } required></textarea>
            </div> : null }
          </div>
          <div className="buttons vouchers">
            <button className="btn yellow small" id="button" onClick={ () => { this.setState({ send: false }); setTimeout(() => { this.saveItem() }, 200) } }><span>{ this.state.action === "edit" ? page.save : page.voucher.btnGenerate }</span></button>
            { this.state.action !== "edit" && this.state.voucher.email && this.state.voucher.email !== "" ? <button className="btn violet small" id="button" onClick={ () => { this.setState({ send: true }); setTimeout(() => { this.saveItem() }, 200) } }><span>{ page.voucher.btnGenerateAndSend }</span></button> : null }
          </div>
        </div>
      )
    }

    return (
        <div className="box">
          { this.state.canGenerate ? <div className="generate-voucher">
            <h3>{ page.generateVoucher }</h3>
            <button className="btn green small" onClick={ () => { this.addItem(); window.gtag('event', "pracownicy_generujvoucher") } }><span>{ page.generateVoucherBtn }</span></button>
          </div> : null }
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/users/u' : '/en/users/u' }>{ page.usersName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/users/v' : '/en/users/v' }>{ page.vouchersName }</NavLink>
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.voucher.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small yellow" onClick={ () => { this.printSelected(); window.gtag('event', "pracownicy_przygotujdodruku") } }><span>{ page.voucher.preparePrint }</span></button>
                <button className="btn small yellow" onClick={ () => { this.setState({ generatePopup: true }); window.gtag('event', "pracownicy_zestawienievoucherow") } }><span>{ page.voucher.downloadReport }</span></button>
              </div>
            </div>
            <div className="filters">
              <div className="actions">
                <p>{ page.voucher.filters }</p>
                <select name="filters" onChange={ this.changeFilter }>
                  <option value="ALL">{ page.voucher.all }</option>
                  <option value="ACTIVE">{ page.voucher.activeTitle }</option>
                  <option value="INACTIVE">{ page.voucher.inactive }</option>
                </select>
              </div>
              <div className="actions">
                <p>{ page.voucher.actions }</p>
                <select name="actions" onChange={ this.checkAction }>
                  <option value="">{ page.voucher.selectAction }</option>
                  { this.state.filter !== "INACTIVE" ? <option value="sendSelected">{ page.voucher.sendSelected }</option> : null }
                  <option value="markSelected">{ page.voucher.markSelected }</option>
                  { this.state.filter !== "INACTIVE" ? <option value="deactivateSelected">{ page.voucher.deactivateSelected }</option> : null }
                </select>
              </div>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <SelectTable lang={ this.props.lang } loading={ this.state.preloader } updateSelected={ this.updateSelected } columns={[
              {
                Header: page.voucher.code,
                accessor: "code",
                minWidth: 120
              },
              {
                Header: page.voucher.active,
                accessor: "active",
                show: false,
                headerClassName: "no-search",
                Cell: row => (<div style={{ textAlign: "center" }} className="check-box"><input id="cb" type="checkbox" disabled checked={ row.value }/><label htmlFor="cb"></label></div>),
                maxWidth: "60px",
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id] === true;
                  } else {
                    return row[filter.id] === false;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.project.all }</option>
                    <option value="true">{ page.project.active }</option>
                    <option value="false">{ page.project.inactive }</option>
                  </select>
              },
              {
                Header: page.voucher.type,
                accessor: "type",
                minWidth: 110,
                headerClassName: "no-search",
                Cell: row => (<span>{ row.value === "ONE_USE_VOUCHER" ? "Jednorazowy" : "Wielorazowy" }</span>),
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  } else {
                    return row[filter.id] === filter.value;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.project.all }</option>
                    <option value="ONE_USE_VOUCHER">Jednorazowy</option>
                    <option value="MULTI_USE_VOUCHER">Wielorazowy</option>
                  </select>
              },
              {
                Header: page.voucher.orderComment,
                accessor: "orderComment",
                minWidth: 120
              },
              {
                Header: page.voucher.amountZl,
                accessor: "amountZl",
                minWidth: 100,
                Cell: row => <span>{ row.value ? (row.value).toFixed(2) : "" }</span>
              },
              {
                Header: page.voucher.maxTariffGr,
                accessor: "maxTariffGr",
                minWidth: 100,
                Cell: row => <span>{ row.value ? (row.value/100).toFixed(2) : "" }</span>
              },
              {
                Header: page.voucher.createDate,
                accessor: "generationTime",
                minWidth: 100,
                Cell: row => ( <span>{ this.parseDate(row.value, true) }</span> ),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row._original.generationTime) {
                    index = this.parseDate(row._original.generationTime, true).toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.businessUser + " / " + page.voucher.personName,
                accessor: "businessUserId",
                minWidth: 130,
                Cell: row => ( <span>{ row.value ? this.state.users[row.value] : page.voucher.personName + ": " + (row.original.personName || "") }</span> ),
                filterMethod: (filter, row) => {
                  let index = ""
                  if ((row[filter.id] !== undefined && row[filter.id] !== null && this.state.users[row[filter.id]]) || row._original.personName) {
                    if (row._original.personName) {
                      let name = "Gość" + row._original.personName
                      index = name.toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                    } else {
                      index = this.state.users[row[filter.id]].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())  
                    }
                    
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.departmentId,
                accessor: "departmentId",
                Cell: row => (<span>{ this.state.departments[row.value] }</span>),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row[filter.id] !== undefined && row[filter.id] !== null && this.state.departments[row[filter.id]]) {
                    index = this.state.departments[row[filter.id]].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.activationDate,
                accessor: "activationDate",
                minWidth: 100,
                Cell: row => ( <span>{ this.parseDate(row.value, true) }</span> ),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row._original.activationDate) {
                    index = this.parseDate(row._original.activationDate, true).toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.expirationDate,
                accessor: "expirationDate",
                minWidth: 100,
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row._original.expirationDate) {
                    index = this.parseDate(row._original.expirationDate, true).toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.projectId,
                accessor: "projectId",
                Cell: row => (<span>{ this.state.projects[row.value] }</span>),
                filterMethod: (filter, row) => {
                  let index = ""
                  if (row[filter.id] !== undefined && row[filter.id] !== null && this.state.projects[row[filter.id]]) {
                    index = this.state.projects[row[filter.id]].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase())
                  } else {
                    index = -1
                  }
                  if (index !== -1) {
                    return true
                  } else {
                    return false
                  }
                }
              },
              {
                Header: page.voucher.orderDays,
                accessor: "orderDays",
                Cell: row => <span>{ this.state.orderDaysMap[row.original.orderDays] }</span>,
                headerClassName: "no-search",
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  } else {
                    return row[filter.id] === filter.value;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Pokaż wszystkie</option>
                    {
                      this.state.orderDaysMapArray.map((item, i) => {
                        return <option key={i} value={ item.id }>{ item.name }</option>
                      })
                    }
                  </select>
              },
              {
                Header: page.voucher.hourLimit,
                accessor: "startHour",
                headerClassName: "no-search",
                minWidth: 120,
                Filter: row => <div></div>,
                Cell: row => {
                  let startHours = ""
                  let endHours = ""
                  if (row.original.startHour !== undefined) {
                    if (row.original.startHour.toString().length === 1) {
                      startHours += "0"+row.original.startHour.toString()
                    } else {
                      startHours += row.original.startHour.toString()
                    }

                    if (row.original.startMinute !== undefined) {
                      if (row.original.startMinute.toString().length === 1) {
                        startHours += ":0"+row.original.startMinute.toString()
                      } else {
                        startHours += ":"+row.original.startMinute.toString()
                      }
                    } else {
                      startHours += ":00"
                    }
                  }

                  if (row.original.endHour !== undefined) {
                    if (row.original.endHour.toString().length === 1) {
                      endHours += "0"+row.original.endHour.toString()
                    } else {
                      endHours += row.original.endHour.toString()
                    }

                    if (row.original.endMinute !== undefined) {
                      if (row.original.endMinute.toString().length === 1) {
                        endHours += ":0"+row.original.endMinute.toString()
                      } else {
                        endHours += ":"+row.original.endMinute.toString()
                      }
                    } else {
                      endHours += ":00"
                    }
                  }
                  return <span>{ startHours+(row.original.startHour !== undefined && row.original.endHour !== undefined ? " - " : "")+endHours }</span>
                },
              },
              {
                Header: page.voucher.carClass,
                accessor: "carClass",
                Cell: row => <span>{ this.state.carClassMap[row.original.carClass] }</span>,
                headerClassName: "no-search",
                minWidth: 100,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  } else {
                    return row[filter.id] === filter.value;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Pokaż wszystkie</option>
                    {
                      this.state.carClassMapArray.map((item, i) => {
                        return <option key={i} value={ item.id }>{ item.name }</option>
                      })
                    }
                  </select>
              },
              {
                Header: page.voucher.sendTo,
                accessor: "email",
                minWidth: 100
              },
              {
                Header: page.voucher.comment,
                accessor: "comment",
                minWidth: 160,
                Cell: row => row.value ? <span>{ row.value }</span> : <div className="options">
                  { this.state.canEdit ? <span onClick={ () => { this.editComment(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>Dodaj uwagi</span> : null }
                </div>
              },
              {
                Header: page.options,
                maxWidth: 160,
                minWidth: 160,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => row.original.active ? <div className="options">
                  { row.original.type === "MULTI_USE_VOUCHER" && this.state.canEdit ? <span onClick={ () => { this.editItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.edit }</span> : null }
                  { this.state.canDeactivate ? <span onClick={ () => { this.deactivateItem(row.original.id) } }><img src="/assets/img/delete-icon.png" alt=""/>{ page.voucher.deactivate }</span> : null }
                </div> : <div className="options"><span>{ page.project.inactive }</span></div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "", quantity: 1 }) }, 500) } }>{ Popup }</Modal>
          <Modal open={ this.state.sendPopup } hidePopup={ () => { this.setState({ sendPopup: false }) } }>
            <div className="password-modal">
              <div className="inputs">
                <h3>{ page.voucher.sendTitle }</h3>
                <p><strong>{ page.voucher.sendDescription }</strong></p>
                <div className="input">
                  <input type="text" pattern="^\S*$" name="sendEmail" id="sendEmail" value={ this.state.sendEmail } onChange={ this.handleInputChange } required/>
                </div>
              </div>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ this.state.printPreloader ? null : this.processSendSelected }><span>{ page.voucher.send }</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
          <Modal open={ this.state.markPopup } hidePopup={ () => { this.setState({ markPopup: false }) } }>
            <div className="password-modal">
              <div className="inputs">
                <h3>{ page.voucher.markTitle }</h3>
                <p><strong>{ page.voucher.markDescription }</strong></p>
                <div className="input">
                  <input type="text" pattern="^\S*$" name="markEmail" id="markEmail" value={ this.state.markEmail } onChange={ this.handleInputChange } required/>
                </div>
              </div>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ this.state.printPreloader ? null : this.processMarkSelected }><span>{ page.voucher.mark }</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
          <Modal open={ this.state.deactivatePopup } hidePopup={ () => { this.setState({ deactivatePopup: false }) } }>
            <div className="password-modal">
              <div className="inputs">
                <h3>{ page.voucher.deactivateTitle2 }</h3>
                <p><strong>{ page.voucher.deactivateConfirm }</strong></p>
              </div>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ this.state.printPreloader ? null : this.processDeactivateSelected }><span>{ page.voucher.deactivate }</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
          <Modal open={ this.state.printPopup } hidePopup={ () => { this.setState({ printPopup: false }) } }>
            <div className="password-modal print">
              <div className="inputs">
                <h3>{ page.voucher.printTitle }</h3>
                <p style={{ textAlign: "center" }}>Przygotowanie do druku zaznaczonych voucherów</p>
              </div>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ this.state.printPreloader ? null : this.processPrint }><span>Pobierz plik PDF</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
          <Modal open={ this.state.generatePopup } hidePopup={ () => { this.setState({ generatePopup: false }) } }>
            <div className="password-modal generate">
              <div className="inputs">
                <h3>{ page.voucher.downloadTitle }</h3>
                <div className="input">
                  <p><strong>{ page.voucher.downloadDescription }</strong></p>
                  <label>{ page.voucher.range }</label>
                  {/* <div className="flex"> */}
                    <div>
                      <label>{ page.voucher.from }</label>
                      <DatePicker
                          selected={this.state.startGenerate}
                          onChange={(date) => {
                            let tempDate = new Date(date)
                            this.setState({ startGenerate: tempDate })
                          }}
                          locale="pl"
                          dateFormat="dd/MM/yyyy, HH:mm:ss"
                          type="search"
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={10}
                          timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                        />
                    </div>
                    <div>
                      <label>{ page.voucher.to }</label>
                      <DatePicker
                          selected={this.state.endGenerate}
                          onChange={(date) => {
                            let tempDate = new Date(date)
                            this.setState({ endGenerate: tempDate })
                          }}
                          locale="pl"
                          dateFormat="dd/MM/yyyy, HH:mm:ss"
                          type="search"
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={10}
                          timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
                        />
                    </div>
                  {/* </div> */}
                  <p className="disclaimer">{ page.voucher.disclaimer }</p>
                </div>
                <div className="input">
                  <div className="check-box"><input name="active" id="cb6" type="radio" value="ACTIVE" checked={ this.state.active === "ACTIVE" } onChange={ this.handleInputChange }/><label htmlFor="cb6">{ page.voucher.activeTitle }</label></div>
                </div>
                <div className="input">
                  <div className="check-box"><input name="active" id="cb7" type="radio" value="INACTIVE" checked={ this.state.active === "INACTIVE" } onChange={ this.handleInputChange }/><label htmlFor="cb7">{ page.voucher.inactive }</label></div>
                </div>
                <div className="input">
                  <div className="check-box"><input name="active" id="cb8" type="radio" value="ALL" checked={ this.state.active === "ALL" } onChange={ this.handleInputChange }/><label htmlFor="cb8">{ page.voucher.all }</label></div>
                </div>
                { /*<div className="input">
                  <p><strong>{ page.voucher.limitTo }</strong></p>
                </div> */ }
              </div>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ this.state.printPreloader ? null : this.processGenerate }><span>{ page.voucher.generate }</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
        </div>
    )
  }
}
