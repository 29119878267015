import React from 'react'
import BaseComponent from './BaseComponent'
import Modal from './Modal'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Select from './Select'
import _ from 'lodash'

export default class extends BaseComponent {

	constructor(props) {
		super(props)
    this.state = { anim: "", langList: false, userProfile: false, searchTerm: this._nullTerm  }
    this._bind('toggleLangList', 'searchUpdated', 'fetchCompany', 'changeCompany')
    this.KEYS_TO_FILTERS = ['name', 'email']

    this.getCompany = _.debounce(e => {
      if (e.length >= 3) {
        this.fetchCompany(e)
      }
    }, 600);
	}

  componentDidMount() {
    setTimeout(() => { this.setState({ anim: "anim" }) }, 400)

    window.addEventListener('click', () => {
      this.setState({ langList: false })
    })

    window.addEventListener('keydown', () => {
      this.setState({ langList: false })
    })
  }

  changeCompany(item) {
    if (item.name) { 
      this.props.switchAccount(item)
      this.setState({ userProfile: false }) 
    }
  }

  fetchCompany(company) {
    company = encodeURIComponent(company)
    fetch(this._apiBase + "account/partner?name=" + company + "&limit=10", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      }
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          //success(result)
          let partners = []
          for (var i = 0; i < Object.entries(result.result.partners).length; i++) {
            let item = { id: Object.entries(result.result.partners)[i][0], name: Object.entries(result.result.partners)[i][1] }
            partners.push(item)
          }
          this.setState({ partners: partners })
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var j = 0; j < result.result.errors.length; j++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[j].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  toggleLangList(e) {
    e.stopPropagation();
    if (this.state.langList) {
      this.setState({ langList: false })
    } else {
      this.setState({ langList: true })
    }
  }

  searchUpdated(term) {
    if (term === "") {
      term = this._nullTerm 
    }
    this.setState({searchTerm: term})
  }

  render() {

    let userModal = ""
    let userLoggedIn = "", shortName = "", partnerName = ""

    if (this.props.user && (this.props.user.firstname || this.props.user.email)) {
      userLoggedIn = "logged-in"
      shortName = this.props.user.firstname && this.props.user.lastname ? this.props.user.firstname[0] + this.props.user.lastname[0] : this.props.user.email[0]

      partnerName = this.props.user.partner ? this.props.user.partner.name : ""
      partnerName = _.truncate(partnerName, {
        'length': 23,
        'separator': ' '
      });

      userModal = (
        <div className="user-modal">
          <div className="user-info">
            <div className="avatar">
              <div className="avatar-inner">
                <strong>{ shortName }</strong>
              </div>
            </div>
            <div className="user-data">
              <p><strong>{ this.props.data.header.userData }</strong></p>
              <p>{ this.props.user.firstname || this.props.user.lastname ? this.props.user.firstname + " " + this.props.user.lastname : this.props.user.email }</p>
              <p>{ this.props.user.partner ? this.props.user.partner.name : "" }</p>
            </div>
            <div className="buttons">
              <button className="btn transparent small" onClick={ () => { this.props.logout(); this.setState({ userProfile: false }); window.gtag('event', "menugora_wyloguj") } }><span>{ this.props.data.header.logout }</span></button>
            </div>
          </div>
          { this.props.user && (this.props.user.userType === "SUPER" || this.props.user.userType === "ADMIN") ? <div className="other-accounts">
            <h4>{ this.props.data.loginPage.selectPartner }</h4>
            <div className="accounts-search">
              <Select noResults={ this.props.data.table.noResults } data={this.state.partners} selected={this.state.partner} filters={['name']} placeholder={ this.props.data.manage.type } onChange={this.getCompany} changeValue={this.changeCompany} />
            </div>
          </div> : null }
        </div>
      )
    }

    return (
      <header className={ this.state.anim + (this.props.loginPage ? " login-header" : "") }>
        <div className="header-logo">
          <a href="https://itaxi.pl" target="_blank" rel="noopener noreferrer"><img className="logo" src="/assets/img/itaxi_logo.svg" alt="iTaxi logo"/></a>
        </div>
        <p className="page-title">{ this.props.data.header.title }</p>
        <TransitionGroup className={ "user-profile " + userLoggedIn }>
          <CSSTransition key={ this.props.user.partner ? this.props.user.partner.name : "" } classNames="user" timeout={{ enter: 500, exit: 250 }} >
            <div onClick={ () => { this.setState({ userProfile: true }) } }>
              <span className="name"><strong>{ this.props.user.firstname || this.props.user.lastname ? this.props.user.firstname + " " + this.props.user.lastname : this.props.user.email }</strong></span>
              <span className="company">{ partnerName }</span>
              <div className="profile-open">
                <span className="small"><strong>{ shortName }</strong></span>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
        <div className={ this.state.langList ? "lang-switch open " + userLoggedIn : "lang-switch " + userLoggedIn }>
          <div className="current-lang" onClick={ this.toggleLangList }>
            { this.props.lang }
          </div>
          <ul className="lang-list">
            <li><a href={ window.location.pathname.replace("/en", "") + window.location.search }>PL</a></li>
            <li><a href={ "/en" + window.location.pathname + window.location.search }>EN</a></li>
          </ul>
        </div>
        <Modal open={ this.state.userProfile } hidePopup={ () => { this.setState({ userProfile: false }) } }>{ userModal }</Modal>
      </header>
    )
  }
}
