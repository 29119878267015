import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'
import Modal from './Modal'
import Select from './Select'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { login: "", pass: "", loginError: false, passError: false, anim: false, popup: false, partners: [], partner: "", searchTerm: this._nullTerm }
    this._bind('handleInputChange', 'checkLogin', 'processLogin', 'onEnterPressed', 'openPartners', 'selectPartner', 'searchUpdated', 'logout', 'fetchCompany', 'changeCompany')
    this.redirect = false
    this.result = {}
    this.KEYS_TO_FILTERS = ['name', 'email']

    this.getCompany = _.debounce(e => {
      if (e.length >= 3) {
        this.fetchCompany(e)
      }
    }, 600);
  }

  UNSAFE_componentWillMount() {
    //this.props.checkSession()
  }

  componentDidMount() {
  	document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)

    document.getElementById('login').focus()

    let user = JSON.parse(localStorage.getItem('user')) || {}

    if((user.userType === "SUPER" || (user.userType === "ADMIN" && user.haveSubpartners === true)) && !user.partner) {
      this.result = user
      setTimeout(() => {
        this.setState({ popup: true })
      }, 1200)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  changeCompany(item) {
    this.setState({ partner: item })
  }

  fetchCompany(company) {
    company = encodeURIComponent(company)
    fetch(this._apiBase + "account/partner?name=" + company + "&limit=10", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      }
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          //success(result)
          let partners = []
          for (var i = 0; i < Object.entries(result.result.partners).length; i++) {
            let item = { id: Object.entries(result.result.partners)[i][0], name: Object.entries(result.result.partners)[i][1] }
            partners.push(item)
          }
          this.setState({ partners: partners })
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var j = 0; j < result.result.errors.length; j++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[j].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  searchUpdated(term) {
    if (term === "") {
      term = this._nullTerm;
    }
    this.setState({searchTerm: term})
  }

  openPartners(token) {
    this.cachedFetch(this._apiBase + "account/partnersList", {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': token
      }
    })
    .then((response) => {
        if (response.status === 401) {
          this.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
    })
    .then((result) => {      
      if (result.answer === "OK") {
        let partners = []
        for (var i = 0; i < Object.entries(result.result.partners).length; i++) {
          let item = { id: Object.entries(result.result.partners)[i][0], name: Object.entries(result.result.partners)[i][1] }
          partners.push(item)
        }
        this.setState({ popup: true, partners: partners })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var j = 0; j < result.result.errors.length; j++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[j].msg}}), true)
        }
        document.getElementById('login').focus()
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
        document.getElementById('login').focus()
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  selectPartner() {
    fetch(this._apiBase + "account/changePartner/"+this.state.partner.id, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      }
    })
    .then((response) => {
        if (response.status === 401) {
          this.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
    })
    .then((result) => {      
      if (result.answer === "OK") {
        this.setState({ popup: false })
        //this.result.partners = this.state.partners
        this.result.partner = this.state.partner
        this.props.loginSuccess(this.result, this.redirect)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        this.setState({ pass: "" })
        document.getElementById('login').focus()
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
        this.setState({ pass: "" })
        document.getElementById('login').focus()
      }
    }, (error) => {
      this.setState({ pass: "" })
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(event) {
  	const target = event.target
  	let value = target.type === 'checkbox' ? target.checked : target.value
  	let name = target.name

  	this.setState({
  	  [name]: value
  	})

  	if (name === "login" && name !== "") {
  		this.setState({ loginError: false })
  	}

  	if (name === "pass" && name !== "") {
  		this.setState({ passError: false })
  	}
  }

  checkLogin() {
  	if (this.state.login === "") {
  		document.getElementById('login').focus()
  		this.setState({ loginError: true })
  	}

  	if (this.state.pass === "" || !this.validatePassword(this.state.pass)) {
  		document.getElementById('pass').focus()
  		this.setState({ passError: true })
  	}

  	if (this.state.login !== "" && this.state.pass !== "" && this.validatePassword(this.state.pass)) {
  		this.processLogin()
  	}
  }

  validatePassword(pass) {
    // const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
    // if(!re.test(pass)) {
    //   this.props.history.replace(this.props.lang === "en" ? "/en/remind" : "/remind")
    //   return false
    // }
    if (pass.length >= 4 && pass.length <= 30) {
      return true
    } else {
      return false
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  processLogin() {
    if (this.props.location.search.indexOf("?redirect=") !== -1) {
      this.redirect = this.props.location.search.replace("?redirect=", "")
    }

    let data  = {login: this.state.login, password: this.state.pass}

    fetch(this._apiBase + "account/userLogin", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
        if (response.status === 401) {
          this.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
    })
    .then((result) => {      
      if (result.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.loginPage.loginSuccessful}}), true)
        localStorage.setItem("accessToken", result.result.accessToken)
        this.result = result.result
        if (result.result.userType === "SUPER" || (result.result.userType === "ADMIN" && result.result.haveSubpartners === true)) {
          this.setState({ popup: true })
          localStorage.setItem("user", JSON.stringify(this.result))
        } else {
          this.props.loginSuccess(this.result, this.redirect)
        }
        window.gtag('event', 'start_zaloguj')
      } else if(result.answer === "CHANGE_PASS") {
        window.gtag('event', 'start_zaloguj_slabe_haslo')
        this.props.history.replace(this.props.lang === "en" ? "/en/remind" : "/remind")
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        document.getElementById('login').focus()
        window.gtag('event', 'start_zaloguj_error')
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
        document.getElementById('login').focus()
        window.gtag('event', 'start_zaloguj_error')
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      window.gtag('event', 'start_zaloguj_error')
    })
  }

  logout() {
      fetch(this._apiBase + "account/userLogout", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': localStorage.getItem("accessToken")
        }
      })
      .then((response) => {
        if (response.status === 401) {
          this.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {        
        if (result.answer === "OK") {

          localStorage.removeItem("user")
          localStorage.removeItem("accessToken")
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
          document.getElementById('login').focus()
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      })
    
  }

  render() {
    const page = this.props.data.loginPage

    let modal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.selectPartner }</h2>
          <div className="accounts-search">
            <Select noResults={ this.props.data.table.noResults } data={this.state.partners} selected={this.state.partner} filters={['name']} placeholder={this.props.data.manage.type} onChange={this.getCompany} changeValue={this.changeCompany} />
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.selectPartner }><span>{ page.select }</span></button>
        </div>
      </div>
    )

    return (
      <main>
        <section className="login">
          <div className={ this.state.anim ? "login-box show" : "login-box" }>
          	<div className="inputs">
              <h3>{ page.loginTitle }</h3>
              <div className="input">
                <label htmlFor="login">{ page.login }</label>
            		<input type="email" name="login" id="login" className={ this.state.loginError ? "input-error" : "" } value={ this.state.login } onChange={ this.handleInputChange } required/>
                { this.state.loginError ? <p className="error">{ page.loginError }</p> : null }
              </div>
              <div className="input">
                <label htmlFor="pass">{ page.password }</label>
            		<input type="password" name="pass" id="pass" className={ this.state.passError ? "input-error" : "" } value={ this.state.pass } onChange={ this.handleInputChange } required/>
            		{ this.state.passError ? <p className="error">{ page.passwordError }</p> : null }
              </div>
          		<div className="btn-holder">
          			<button className="btn yellow" id="button" onClick={ () => { this.checkLogin(); window.gtag('event', 'start_reset') } }><span>{ page.btnLogin }</span></button>
          		</div>
              <NavLink className="remind" to={ this.props.lang === "en" ? "/en/remind" : "/remind" }>{ page.remind }</NavLink>
          	</div>
            <div className="separator"></div>
            <div className="benefits">
              <h3>{ page.benefitsTitle }</h3>
              <ul>
                { page.benefits.map((item, i) => {
                  return (
                    <li key={ i }>{ item }</li>
                  )
                }) }
              </ul>
              <p className="access" dangerouslySetInnerHTML={{ __html: page.access }}></p>
            </div>
          </div>
        </section>
        <Footer/>
        <Modal close="hide" open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false, pass: "" }); this.logout() } }>{ modal }</Modal>
      </main>
    )
  }
}
