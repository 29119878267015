import 'react-app-polyfill/ie9'
import 'custom-event-polyfill'
import 'fetch-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import MainView from './app/MainView'

export default class Main {

	init() {
		if (window.location.pathname.indexOf("/en") !== -1) {
			this.preloadJSON("/data/en/translations.json")
			this.lang = "en"
		} else {
			this.preloadJSON("/data/pl/translations.json")
			this.lang = "pl"
		}
	}

	preloadJSON( dataURL) {
		fetch(dataURL)
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    this.data = result
		    this.ready()
		})
	}

	ready() {
		this.render()
	}

	render() {
		const mainContainer = document.getElementById('app')

		ReactDOM.render(
			<Router>
				<MainView 
					lang={ this.lang }
					data={ this.data }/>
			</Router>
		, mainContainer)
	}
}

const app = new Main()
app.init()