/* Component for easier ES6 binding */

import { Component } from 'react';

export default class BaseComponent extends Component {

	constructor(props) {
		super(props);
		// dev
		this._apiBase = document.location.hostname === "npb-stage.jsdev.com.pl" ? "https://stagingitaxi.demo.eo.pl/taxi-business-client-web/rest/Vs7mBQZx0n2eer/" : "https://taxi.demo.eo.pl/taxi-business-client-web/rest/1234567890/";
		this._apiBaseBM = document.location.hostname === "npb-stage.jsdev.com.pl" ? "https://stagingitaxi.demo.eo.pl/taxi-business-client-web/rest/" : "https://taxi.demo.eo.pl/taxi-business-client-web/rest/";
		// prod
		// this._apiBase = "https://itaxi.pl/taxi-business-client-web/rest/Vs7mBQZx0n2eer/";
		// this._apiBaseBM = "https://itaxi.pl/taxi-business-client-web/rest/";
		// for search
		this._nullTerm = "___NULL___";
	}

	_bind(...methods) {
		methods.forEach((method) => this[method] = this[method].bind(this));
	}

	setContent(id) {
		this.setState({ index: id })
	}

	setWrapperHeight(h) {
		this.props.setHeight(h)
	}

	leadingZero(day) {
		let d = parseInt(day)
		if (d < 10) {
			return '0' + d
		} else {
			return '' + d
		}
	}

	cachedFetch(url, options) {
		// Use the URL as the cache key to localStorage
		let cacheKey = url

		// START new cache HIT code
		let cached = localStorage.getItem(cacheKey)
		if (cached !== null) {
			// it was in localStorage! Yay!
			let response = new Response(new Blob([cached]))
			return Promise.resolve(response)
		}
		// END new cache HIT code

		return fetch(url, options).then(response => {
			// let's only store in cache if the content-type is
			// JSON or something non-binary
			if (response.status === 200) {
				let ct = response.headers.get('Content-Type')
				if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
					// There is a .json() instead of .text() but
					// we're going to store it in localStorage as
					// string anyway.
					// If we don't clone the response, it will be
					// consumed by the time it's returned. This
					// way we're being un-intrusive.
					response.clone().text().then(content => {
						localStorage.setItem(cacheKey, content)
					})
				}
			}
			return response
		})
	}

	nearestHalfHour() {
		let timeToReturn = new Date();
		timeToReturn.setMinutes(timeToReturn.getMinutes() + 30);
		timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
		timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
		timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
		return timeToReturn;
	}

	parseDate(d, hours, showSeconds) {
		let date = new Date(d)
		let seconds = ":" + this.leadingZero(date.getSeconds())

		if (showSeconds === false) {
			seconds = ""
		}

		if (hours) {
			return this.leadingZero(date.getDate()) + "." + this.leadingZero(date.getMonth() + 1) + "." + date.getFullYear() + ", " + this.leadingZero(date.getHours()) + ":" + this.leadingZero(date.getMinutes()) + seconds
		} else {
			return this.leadingZero(date.getDate()) + "." + this.leadingZero(date.getMonth() + 1) + "." + date.getFullYear()
		}
	}
}