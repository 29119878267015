import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import Modal from './Modal'
import Select from './Select'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      invoice: {}, invoiceS: [], itemPopup: false, id: null, rewrite: {},
      preloader: true
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'changeValue', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'handleInputChange',
      'deleteSuccess',
      'update',
      'shortFetchPDF',
      'getPDF'
    )

    this.endpoint = "invoices"

    this.listItems()
  
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ invoice: {}, itemPopup: false, id: null, rewrite: {}, preloader: true })
    this.listItems()
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/reports/r" : "/en/reports/r")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      this.setState({ preloader: false })
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  shortFetchPDF(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else if(response.status === 204) {
        this.noContent = true
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.invoice.noData }}), true)
      } else {
        return response.blob()
      }
    })
    .then((result) => {
      if ((!this.noContent)) {
        var url = window.URL.createObjectURL(result);
        var a = document.createElement('a');
        a.href = url;
        a.download = "invoices.pdf";
        document.body.appendChild(a);
        a.click();    
        a.remove(); 
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.exportSuccessfully }}), true)
        this.setState({ printPopup: false})
      }
      setTimeout(() => { this.setState({ printPreloader: false }) }, 300)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state[type]

    item[name] = value

    this.setState({
      [type]: item
    })
  }

  listItems() {
    this.shortFetch("GET", this.endpoint+"", null, this.successListItems)
  }

  successListItems(data) {    
    this.setState({ data: data.result.invoices, itemPopup: false, preloader: false })
  }

  addItem() {
    this.setState({ invoice: { active: true }, itemPopup: true, action: "add" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    this.setState({ invoice: data.result.invoice, itemPopup: true, action: "edit" })
  }

  saveItem() {
    if (this.state.invoice.id) {
      this.shortFetch("POST", this.endpoint+"/edit", { invoice: this.state.invoice }, () => { 
        this.setState({ itemPopup: false, action: "" })
        this.listItems()
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
      })
    } else {
      this.shortFetch("POST", this.endpoint+"/add", { invoice: this.state.invoice }, () => { 
        this.setState({ itemPopup: false, action: "" })
        this.listItems()
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
      })
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.rewriteItem()
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems()
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeValue(item) {
    this.setState({ rewrite: item })
  }

  getPDF(id) {
    this.shortFetchPDF("GET", "invoices/"+id, null, (data) => {
      console.log(data)
    })
  }

  render () {
    const page = this.props.data.manage

    let Popup = null

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.invoice.confirmRewrite : page.invoice.confirm }</strong></p>
            { this.state.action === "delete_rewrite" ? <div className="input">
              <label>{ page.invoice.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={"Wpisz..."} id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label htmlFor="name">{ page.invoice.name }</label>
              <input type="text" name="name" id="name" value={ this.state.invoice.name } onChange={ this.handleInputChange.bind(null, "invoice") } required/>
            </div>
            <div className="input">
              <label htmlFor="description">{ page.invoice.description }</label>
              <textarea type="number" name="description" id="description" value={ this.state.invoice.description } onChange={ this.handleInputChange.bind(null, "invoice") } required></textarea>
            </div>
            <div className="input">
              <div className="check-box"><input name="active" id="cb2" type="checkbox" checked={ this.state.invoice.active !== undefined ? this.state.invoice.active : true } onChange={ this.handleInputChange.bind(null, "invoice") }/><label htmlFor="cb2">Czy aktywny?</label></div>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    return (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/reports/r' : '/en/reports/r' }>{ page.reportsName }</NavLink>
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <span className="sep">|</span> }
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <NavLink to={ this.props.lang === "pl" ? '/reports/i' : '/en/reports/i' }>{ page.invoicesName }</NavLink> }
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.invoice.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={[
              {
                Header: page.invoice.docNo,
                accessor: "documentEnovaID"
              },
              {
                Header: page.invoice.docDate,
                accessor: "invoiceDate"
              },
              {
                Header: page.options,
                maxWidth: 180,
                minWidth: 180,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => <div className="options"><span onClick={ this.getPDF.bind(null, row.original.encodedDocumentEnovaID) }><img src="/assets/img/edit-icon.png" alt=""/>{ page.invoice.download }</span></div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ Popup }</Modal>
        </div>
    )
  }
}
