import React, { Component } from 'react'

export default class extends Component {

  render () {

    return (
      <footer>
      	<div className="container">
	        <p className="copyrights">Copyrights 2019 iTaxi</p>
	    </div>
      </footer>
    )
  }
}
