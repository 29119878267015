import React from 'react'
import BaseComponent from './BaseComponent'
import ReactTable from "react-table"
import "react-table/react-table.css"
var elementResizeEvent = require('element-resize-event')

function filterComponent(props) {
  const filter = props.filter
  const onChange = props.onChange
  return <input type="text" name={"filter_"+props?.column?.id || ""} onChange={event => { onChange(event.target.value)} } value={filter ? filter.value : ""}/>
}

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { arrow: false, leftArrow: false }
    this._bind('onScroll')
  }

  componentDidMount() {
    let table = document.querySelector("div.rt-table")
    let tableBody = document.querySelector("div.rt-tbody")

    if (table.offsetWidth + table.scrollLeft < tableBody.offsetWidth) {
      this.setState({ arrow: true })
    } else {
      this.setState({ arrow: false })
    }

    if (table.scrollLeft > 0) {
      this.setState({ leftArrow: true })
    } else {
      this.setState({ leftArrow: false })
    }

    table.addEventListener('scroll', this.onScroll)

    elementResizeEvent(table, () => {
      this.onScroll()
    })
  }

  onScroll() {
    let table = document.querySelector("div.rt-table")
    let tableBody = document.querySelector("div.rt-tbody")

    if (table.offsetWidth + table.scrollLeft < tableBody.offsetWidth) {
      this.setState({ arrow: true })
    } else {
      this.setState({ arrow: false })
    }

    if (table.scrollLeft > 0) {
      this.setState({ leftArrow: true })
    } else {
      this.setState({ leftArrow: false })
    }
  }

  render () {

    return (
      <div className="table">
        <div className={ this.state.leftArrow ? "table-arrow-left show" : "table-arrow-left" } onClick={ () => { let table = document.querySelector("div.rt-table"); table.scrollLeft = 0; } }><span></span></div>
        <ReactTable
          FilterComponent={filterComponent}
          defaultFilterMethod={(filter, row) => (row[filter.id] !== undefined && row[filter.id] !== null ? row[filter.id].toString().toLowerCase().indexOf(filter.value.toString().toLowerCase()) : -1) !== -1}
          columns={this.props.columns}
          defaultSorted={[{
            id: 'deleted',
            desc: false
          },
          {
            id: 'id',
            desc: true
          }]}
          data={this.props.data}
          filterable
          loading={ this.props.loading || false }
          defaultPageSize={10}
          minRows={2}
          className="-striped -highlight"
          previousText={ this.props.lang === "pl" ? 'Poprzednia' : 'Previous'}
          nextText={ this.props.lang === "pl" ? 'Następna' : 'Next'}
          noDataText={ this.props.lang === "pl" ? 'Brak wyników!' : 'No results!'}
          pageText={ this.props.lang === "pl" ? 'Strona' : 'Page'}
          ofText={ this.props.lang === "pl" ? 'z' : 'of'}
          rowsText={ this.props.lang === "pl" ? 'wyników' : 'results'}
          loadingText= { this.props.lang === "pl" ? "Pobieranie danych..." : "Loading..." }
          getTrProps={(state, rowInfo, instance) => {
            if (rowInfo) {
              return {
                style: {
                  background: rowInfo.row.deleted || rowInfo.original.deleted ? '#ddd' : ''
                }
              }
            }
            return {};
          }}
        />
        <div className={ this.state.arrow ? "table-arrow show" : "table-arrow" } onClick={ () => { let table = document.querySelector("div.rt-table"); table.scrollLeft = 1000; } }><span></span></div>
      </div>
    )
  }
}
