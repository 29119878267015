import React from 'react'
import BaseComponent from './BaseComponent'
import Users from './Users'
import Vouchers from './Vouchers'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { page: this.props.match.params.page ? this.props.match.params.page : "u", type: this.props.match.params.type ? this.props.match.params.type : "list", users: [], vouchers: [], user: {}, voucher: {} }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/users/u" : "/en/users/u")
    }
  }


  render () {
    let pageInner = null

    switch(this.state.page) {
      case 'u':
        pageInner = <Users user={ this.props.user } {...this.props} showAdd={ this.props.match.params.type === "add" ? true : false }/>
      break
      case 'v':
        pageInner = <Vouchers user={ this.props.user } {...this.props}/>
      break
      default:
        pageInner = <Users user={ this.props.user } {...this.props} showAdd={ this.props.match.params.type === "add" ? true : false }/>
      break
    }

    return (
      <section className="users-page">
        { pageInner }
      </section>
    )
  }
}
