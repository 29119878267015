import React from 'react'
import BaseComponent from './components/BaseComponent'
import { withRouter, Switch, Route, NavLink } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Header from './components/Header'
import ErrorPage from './components/ErrorPage'
import LoginPage from './components/LoginPage'
import RemindPage from './components/RemindPage'
import RemindPageHash from './components/RemindPageHash'
import UsersPage from './components/UsersPage'
import ReportsPage from './components/ReportsPage'
import ManagePage from './components/ManagePage'
import Dashboard from './components/Dashboard'
import Ordered from './components/Ordered'
import Cards from './components/Cards'
import AddCardPage from './components/AddCardPage'
import Order from './components/OrderPage'
import OrderiPaczka from './components/OrderiPaczkaPage'
import Settings from './components/Settings'
import SideNav from './components/SideNav'
import _ from 'lodash'
import Modal from './components/Modal'
import '../style.css'

class MainView extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { screen : { width: window.innerWidth, height: window.innerHeight }, nav: window.innerWidth > 1020 ? true : false, notifications: [], "user": { partner: {} }, checked: false, orders: [], tapAgainToClose: false, orderToRetry: {}, retryPopup: false, orderingBlocked: false, cardExist: false }
    this._bind('toggleNav', 'hideNav', 'addNotify', 'closeNotify', 'checkSession', 'logout', 'loginSuccess', 'switchAccount', 'checkOrders', 'shortFetch', 'retryOrder', 'hideLoader')

    this.timer = setInterval(() => { 
      if(!((this.state.user.userType === "SUPER" || (this.state.user.userType === "ADMIN" && this.state.user.haveSubpartners === true)) && !this.state.user.partner)) {
        this.checkOrders() 
      }
    }, 10000)
    window.addEventListener("UPDATE_STATUS", (data) => { this.checkOrders() })
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.checkSession()
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result?.answer === "OK") {
        success(result)
      } else if(result?.answer === "ERROR" && result?.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else if(result?.answer === "IN_PROGRESS") {
        this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 400)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  checkOrders() {
    if (this.state.user.id) {
      let alerts = JSON.parse(localStorage.getItem("alerts")) || []

      this.shortFetch("GET", "order/history", null, (data) => {
        if(data?.result) {
          this.setState({ orderingBlocked: data.result.orderingBlocked, cardExist: data.result.cardExist })
        }
        if (data.result.orders) {
          for (var i = 0; i < data.result.orders.length; i++) {
            let single = _.filter(alerts, { id: data.result.orders[i].id })[0] || {}
            let index = alerts.indexOf(single)

            if (single.status !== data.result.orders[i].status) {
              if (single.id) {              
                alerts[index] = { id: data.result.orders[i].id, status: data.result.orders[i].status, checked: false }
              } else {
                alerts.push({ id: data.result.orders[i].id, status: data.result.orders[i].status, checked: false })
              }
              window.dispatchEvent(new CustomEvent("UPDATE_ORDERS", { 'detail': {}}), true)
              window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "<span>" + this.props.data.ordered.status + "<strong>" + data.result.orders[i].fullname + "</strong></span>"}}), true)
              if (data.result.orders[i].status === "Kurs zakończony niepowodzeniem" && data.result.orders[i].toRetry) {
                this.setState({ orderToRetry: data.result.orders[i], retryPopup: true })
              }
            }
          }
          localStorage.setItem("alerts", JSON.stringify(alerts))
          localStorage.setItem("orders", JSON.stringify(data.result.orders))
          this.setState({ orders: data.result.orders })
        } else {
          this.setState({ orders: [] })
        }
      })
    }
  }

  checkSession() {
    this.setState({ checked: false })
    let pathname = this.props.location.pathname

    if (pathname === "/" || pathname === "/en" || this.props.location.pathname === '/login' || this.props.location.pathname === '/en/login' || this.props.location.pathname === '/remind' || this.props.location.pathname === '/en/remind' || this.props.location.pathname === '/dodaj-karte' || this.props.location.pathname === '/en/dodaj-karte') {
      pathname = false
    }

      fetch(this._apiBase + "account/ping", {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': localStorage.getItem("accessToken")
        }
      })
      .then((response) => {
          if (response.status === 401) {

            if (localStorage.getItem("accessToken")) {
              window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.sessionError }}), true)
            }
            
            localStorage.removeItem("user")
            localStorage.removeItem("accessToken")            

            this.setState({ user: {} })
            if(pathname !== "/dodaj-karte" && pathname !== "/en/dodaj-karte" && pathname !== "/change-pass" && pathname !== "/en/change-pass") {
              if (this.props.lang === "pl") {
                this.props.history.replace(pathname ? "/login?redirect=" + pathname : "/login")
              } else {
                this.props.history.replace(pathname ? "/en/login?redirect=" + pathname : "/en/login")
              }
            }
          } else if( response.status >= 500 ) {
            if (this.props.lang === "pl") {
              this.props.history.replace("/error/"+response.status)
            } else {
              this.props.history.replace("/en/error/"+response.status)
            }
          } else {
            if (this.props.location.pathname === "/" || this.props.location.pathname === "/en") {
              if (this.props.lang === "pl") {
                this.props.history.replace("/dashboard")
              } else {
                this.props.history.replace("/en/dashboard")
              }
            }
            if (localStorage.getItem("user")){
              this.setState({ user: JSON.parse(localStorage.getItem("user")) })
            }
            let user = JSON.parse(localStorage.getItem('user')) || {}

            if(!((user.userType === "SUPER" || (user.userType === "ADMIN" && user.haveSubpartners === true)) && !user.partner)) {
              this.checkOrders()
            }
          }
          this.setState({ checked: true })
      })
      .then((result) => {        
      }, (error, status) => {
        if (this.props.lang === "pl") {
          this.props.history.replace("/error")
        } else {
          this.props.history.replace("/en/error")
        }
        this.setState({ checked: true })
      })
  }

  loginSuccess(data, redirect) {
    localStorage.setItem("user", JSON.stringify(data))
    this.setState({ user: data })

    if (redirect) {
      this.props.history.replace(redirect)
    } else {
      if (this.props.lang === "pl") {
        this.props.history.replace("/dashboard")
      } else {
        this.props.history.replace("/en/dashboard")
      }
    }

    this.checkOrders()
  }

  switchAccount(data) {
    setTimeout(() => {
      fetch(this._apiBase + "account/changePartner/"+data.id, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': localStorage.getItem("accessToken")
        }
      })
      .then((response) => {        
          if (response.status === 401) {
            this.checkSession()
          } else {
            return response.json()
          }
      })
      .then((result) => {        
        if (result.answer === "OK") {
          let user = this.state.user
          user.partner = data
          this.setState({ user: user })
          localStorage.setItem("user", JSON.stringify(user))
          window.dispatchEvent(new CustomEvent("UPDATE", { 'detail': {}}), true)
          this.checkOrders()
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }
          //document.getElementById('login').focus()
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
          //document.getElementById('login').focus()
        }
      }, (error, status) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      })
    }, 1000)
  }

  logout() {
      fetch(this._apiBase + "account/userLogout", {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'X-api-key': '46B9D48A125733B4C9226CE570007',
          'X-business-token': this.state.user.accessToken
        }
      })
      .then((response) => {
        if (response.status === 401) {
          this.checkSession()
        } else {
          return response.json()
        }
      })
      .then((result) => {        
        if (result.answer === "OK") {

          localStorage.removeItem("user")
          localStorage.removeItem("accessToken")          

          if (this.props.lang === "pl") {
            this.props.history.replace("/login")
          } else {
            this.props.history.replace("/en/login")
          }

          this.setState({ user: { partner: {} } })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
          document.getElementById('login').focus()
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      })
    
  }

  UNSAFE_componentWillMount() {
    if(this.props.location.pathname !== "/dodaj-karte" && this.props.location.pathname !== "/en/dodaj-karte") {
      this.checkSession()
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
    window.addEventListener("NOTIFY", (data) => { this.addNotify(data) })

    let user = JSON.parse(localStorage.getItem('user')) || {}

    if((user.userType === "SUPER" || (user.userType === "ADMIN" && user.haveSubpartners === true)) && !user.partner && this.props.location.pathname !== "/dodaj-karte" && this.props.location.pathname !== "/en/dodaj-karte") {
      if (this.props.lang === "pl") {
        this.props.history.replace("/login")
      } else {
        this.props.history.replace("/en/login")
      }
    }
  }

  addNotify(e) {
    let stamp = new Date()
    stamp = stamp.getTime()
    let temp = this.state.notifications
    let item = { type: e.detail.type, content: e.detail.content, stamp: stamp }
    temp.push(item)
    this.setState({ notifications: temp })
    setTimeout(() => {
      this.closeNotify(stamp)
    }, 10000)
  }

  closeNotify(stamp) {
    let index = 999
    let temp = this.state.notifications
    for (var i = 0; i < this.state.notifications.length; i++) {
      if(this.state.notifications[i].stamp === stamp) {
        index = i
      }
    }
    temp.splice(index, 1);
    this.setState({ notifications: temp })
  }

  scrollTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((nextProps.location.pathname === "/" || nextProps.location.pathname === "/en") && this.state.user.firstname) {
      if (this.props.lang === "pl") {
        this.props.history.replace("/dashboard")
      } else {
        this.props.history.replace("/en/dashboard")
      }
    }

    if (nextProps.location !== this.props.location) {
      setTimeout(this.scrollTop, 250)
    }
  }

  handleResize(e) {
    this.setState({ screen : { width: window.innerWidth, height: window.innerHeight }})
  }

  toggleNav() {
    if (this.state.nav) {
      this.setState({ nav: false })
    } else {
      this.setState({ nav: true })
    }
  }

  hideNav() {
    if (this.state.screen.width < 1020) {
      this.setState({ nav: false })
    }
  }

  retryOrder() {
    this.shortFetch("POST", "order/retry", { orderId: this.state.orderToRetry.id }, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Zamówiono ponownie"}}), true)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się zamówić ponownie"}}), true)
      }
      this.setState({ retryPopup: false })
    })
  }

  hideLoader() {
    this.setState({ checked: true })
  }

  render() {

    let notifications = this.state.notifications.map((item, i) => {
      return (
        <CSSTransition key={ item.stamp } classNames="notification" timeout={{ enter: 500, exit: 500 }} >
          <div className={ "notification " + item.type } style={{ transform: "translateY("+(i*50)+"px)" }} onClick={ this.closeNotify.bind(null, item.stamp) }>
            <div className="close"></div>
            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
          </div>
        </CSSTransition>
      )
    })

    let langPrefix = ""

    if (this.props.lang === "en") {
      langPrefix = "/en"
    }

    let retryModal = (
      <div className="password-modal order-modal">
        <div className="inputs">
          <h4>{ this.props.data.ordered.alert }</h4>
        </div>
        <div className="buttons">
          <NavLink to="/ordered" className="btn yellow small" id="button" onClick={ () => { window.gtag('event', "zamowienie_powtorz"); this.setState({ retryPopup: false, orderToRetry: {} }) } }><span>{ this.props.data.header.ordered }</span></NavLink>
          <button className="btn transparent small" id="button" onClick={ () => { this.setState({ retryPopup: false, orderToRetry: {} }) } }><span>{ this.props.data.header.close }</span></button>
        </div>
      </div>
    )

    let showNav = false

    if(this.state.user && (this.state.user.firstname || this.state.user.email) && this.props.location.pathname.indexOf("/error") === -1) {
      showNav = true
    }

    if((this.state.user?.userType === "SUPER" || (this.state.user?.userType === "ADMIN" && this.state.user?.haveSubpartners === true)) && !this.state.user?.partner) {
      showNav = false
    }

    return (
      <div className="app-inner">
        <Header loginPage={ this.props.location.pathname === "/login" || this.props.location.pathname === "/dodaj-karte" ? true : false } data={ this.props.data } lang={ this.props.lang } user={ this.state.user } logout={ this.logout } switchAccount={ this.switchAccount }/>
        {showNav ? <SideNav orders={ this.state.orders } screen={ this.state.screen } data={ this.props.data } user={ this.state.user } toggleNav={ this.toggleNav } hideNav={ this.hideNav } nav={ this.state.nav } lang={ this.props.lang } location={ this.props.location }/> : null }
        { this.state.checked || this.props.location.pathname === "/dodaj-karte" || this.props.location.pathname === "/en/dodaj-karte" ? <TransitionGroup className={ this.state.nav ? "page-holder open" : "page-holder" }>
          <>
          { this.state.orderingBlocked && this.state.user && (this.state.user.firstname || this.state.user.email) && this.props.location.pathname.indexOf("/error") === -1 ? <div className="main-notification"><p dangerouslySetInnerHTML={{ __html: this.state.cardExist ? this.props.data.header.orderingBlockedPayments : this.props.data.header.orderingBlocked }}></p>{ this.state.user.cardManagementMenu && this.state.user.userType !== "USER" && this.props.location.pathname.indexOf("/payments") === -1 ? <p className="payments"><NavLink to="/payments">{ this.props.data.header.payments }</NavLink></p> : null }</div> : null }
          <main>
          <CSSTransition key={ this.props.location.pathname } classNames="page" timeout={{ enter: 500, exit: 250 }} >
            <Switch location={ this.props.location }>
              <Route  path={ langPrefix + "/" } exact render={(props) => (<LoginPage user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } loginSuccess={ this.loginSuccess } checkSession={ this.checkSession }/>)}/>
              <Route  path={ langPrefix + "/login" } exact render={(props) => (<LoginPage lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } history={this.props.history} loginSuccess={ this.loginSuccess } checkSession={ this.checkSession }/>)}/>
              <Route  path={ langPrefix + "/dodaj-karte" } exact render={(props) => (<AddCardPage lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } hideLoader={ this.hideLoader }/>)}/>
              <Route  path={ langPrefix + "/remind" } exact render={(props) => (<RemindPage lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/change-pass" } exact render={(props) => (<RemindPageHash lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/dashboard" } exact render={(props) => (<Dashboard user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } checkSession={ this.checkSession } location={ this.props.location } {...props}/>)}/>
              <Route  path={ langPrefix + "/ordered" } exact render={(props) => (<Ordered lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/payments/:id?" } exact render={(props) => (<Cards lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/order/:type?/:id?" } exact render={(props) => (<Order user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/order-ipaczka" } exact render={(props) => (<OrderiPaczka user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/reports/:page?/:type?" } render={(props) => (<ReportsPage user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/users/:page?/:type?" } render={(props) => (<UsersPage user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/manage/:page?/:type?" } render={(props) => (<ManagePage user={ this.state.user } lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/settings" } exact render={(props) => (<Settings lang={ this.props.lang } data={ this.props.data } screen={ this.state.screen } location={ this.props.location } user={ this.state.user } logout={ this.logout } switchAccount={ this.switchAccount } checkSession={ this.checkSession } {...props}/>)}/>
              <Route  path={ langPrefix + "/error/:error?" } render={(props) => (<ErrorPage lang={ this.props.lang } data={ this.props.data } {...props}/>)} />
              <Route render={(props) => (<ErrorPage data={ this.props.data } {...props}/>)} />
            </Switch>
          </CSSTransition>
          </main>
          </>
        </TransitionGroup> : <section className="preloader-page">
          <div className="preloader-inner"><span></span></div>
        </section> }
        <TransitionGroup className="notifications">
          { notifications }
        </TransitionGroup>
        <Modal open={ this.state.retryPopup } hidePopup={ () => { this.setState({ retryPopup: false }) } }>{ retryModal }</Modal>
      </div>
    )
  }
}

export default withRouter(MainView)