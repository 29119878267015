import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import Modal from './Modal'

export default class extends BaseComponent {

  constructor(props) {
  	super(props)
  	this.state = { anim: "", popup: false, newStatus: false }
    this._bind('showPopup', 'onScroll')
  }

  componentDidMount() {
  	this.timer = setTimeout(() => { this.setState({ anim: "anim" }) }, 400)
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    if (this.props.screen.width >= 1020 && (((document.body.scrollTop || document.documentElement.scrollTop) + window.innerHeight - 60) - document.getElementById('side-nav').offsetHeight ) > 0) {
      document.getElementById('side-nav').style.top = (60 + ( ((document.body.scrollTop || document.documentElement.scrollTop) + window.innerHeight - 60) - document.getElementById('side-nav').offsetHeight ))+"px"
    } else {
      if (this.props.screen.width >= 1020) {
        document.getElementById('side-nav').style.top = 60+"px"
      } else {
        document.getElementById('side-nav').style.top = 120+"px"
      }
    }
  }

  showPopup(e) {
    e.preventDefault()
    this.props.hideNav()
    this.setState({ popup: true })
  }

  render() {
    let alerts = JSON.parse(localStorage.getItem("alerts")) || []
    let orders = this.props.orders || []
    let notCheckedStatuses = []

    for (var i = 0; i < orders.length; i++) {
      let single = _.filter(alerts, { id: orders[i].id })[0] || {}

      if (single.id === orders[i].id && single.checked === false) {
        notCheckedStatuses.push(single)
      }
    }

    let showBadge = notCheckedStatuses.length > 0 ? true : false

    const orderModal = (
      <div className="order-modal">
        <h2>{ this.props.data.dashboard.orderTaxi }</h2>
        <NavLink className="btn yellow small" to={ this.props.lang === "pl" ? "/order" : "/en/order" } onClick={ () => { this.setState({ popup: false }); window.gtag('event', 'menubok_zamawia_pracownicy') } }><span>{ this.props.data.sideNav.employee }</span></NavLink>
        <NavLink className="btn yellow small" to={ this.props.lang === "pl" ? "/order/guest" : "/en/order/guest" } onClick={ () => { this.setState({ popup: false }); window.gtag('event', 'menubok_zamawia_goscie') } }><span>{ this.props.data.sideNav.guest }</span></NavLink>
      </div>
    )

  	let nav
  	this.props.nav ? nav = "side-nav open" : nav = "side-nav"

    let navItems = this.props.data.nav.map((item, i) => {
      let comp = null
      if (item.type === "popup") {
        if (this.props.user.userType === "USER" && !this.props.user.extraFilter) {
          comp = (
            <li key={ i }><NavLink to={ this.props.lang === "pl" ? item.route : "/en" + item.route } exact={ false } onClick={ () => { this.props.hideNav(); window.gtag('event', item.event) } }><div className="img"><div className={ (item.route === "/ordered" && showBadge) ? "pulse" : "" }></div><i dangerouslySetInnerHTML={{ __html: item.icon }}/></div><span>{ item.name }</span></NavLink></li>
          )
        } else {
          comp = (
            <li key={ i }><NavLink to={ this.props.lang === "pl" ? item.route : "/en" + item.route } exact={ false } onClick={ this.showPopup }><div className="img"><div className={ (item.route === "/ordered" && showBadge) ? "pulse" : "" }></div><i dangerouslySetInnerHTML={{ __html: item.icon }}/></div><span>{ item.name }</span></NavLink></li>
          )
        }
      } else {
        if (item.admin) {
          if (item.dependOn === "cardManagementMenu") {
            if (this.props.user.cardManagementMenu) {
              comp = (
                <li key={ i }><NavLink to={ this.props.lang === "pl" ? item.route : "/en" + item.route } exact={ item.route === "/" ? true : false } onClick={ () => { this.props.hideNav(); window.gtag('event', item.event) } }><div className="img"><div className={ (item.route === "/ordered" && showBadge) ? "pulse" : "" }></div><i dangerouslySetInnerHTML={{ __html: item.icon }}/></div><span>{ item.name }</span></NavLink></li>
              )
            }
          } else if (this.props.user.userType !== "USER") {
            comp = (
              <li key={ i }><NavLink to={ this.props.lang === "pl" ? item.route : "/en" + item.route } exact={ item.route === "/" ? true : false } onClick={ () => { this.props.hideNav(); window.gtag('event', item.event) } }><div className="img"><div className={ (item.route === "/ordered" && showBadge) ? "pulse" : "" }></div><i dangerouslySetInnerHTML={{ __html: item.icon }}/></div><span>{ item.name }</span></NavLink></li>
            )
          }
        } else {
           comp = (
            <li key={ i }><NavLink to={ this.props.lang === "pl" ? item.route : "/en" + item.route } exact={ item.route === "/" ? true : false } onClick={ () => { this.props.hideNav(); window.gtag('event', item.event) } }><div className="img"><div className={ (item.route === "/ordered" && showBadge) ? "pulse" : "" }></div><i dangerouslySetInnerHTML={{ __html: item.icon }}/></div><span>{ item.name }</span></NavLink></li>
          )
        }
      }
      return comp
    })

    const activeItem = _.filter(this.props.data.nav, (item) => { if (this.props.location.pathname.replace("/en", "").indexOf(item.route) !== -1 || this.props.location.pathname.replace("/en", "").indexOf(item.route2) !== -1) { return item } })[0] || {}

    return (
      <div className={ nav + " " + this.state.anim }>
        <div className="bg"></div>
	     	<div className="expand" onClick={ this.props.toggleNav }></div>
        <div className="active-mobile" onClick={ this.props.toggleNav }><div className="icon"><i dangerouslySetInnerHTML={{ __html: activeItem.icon }}/></div><h4>{ activeItem.name }</h4></div>
        <nav id="side-nav">
          <ul className="nav-items">
            { navItems }
          </ul>
          <div className="side-nav-content">
            <p>{ this.props.data.sideNav.orderApp }<br/><a href="https://itaxi.pl/pobierz" target="_blank" rel="noopener noreferrer" onClick={ () => { window.gtag('event', 'menubok_pobierzapke') } }>{ this.props.data.sideNav.download }</a></p>
            <img className="qr" src="/assets/img/qr-code.jpg" alt=""/>
            <div className="new-app">
              <ul className="anim-bg">
                <li><img src="/assets/img/anim/1.png" alt=""/></li>
                <li><img src="/assets/img/anim/2.png" alt=""/></li>
                <li><img src="/assets/img/anim/3.png" alt=""/></li>
                <li><img src="/assets/img/anim/4.png" alt=""/></li>
                <li><img src="/assets/img/anim/5.png" alt=""/></li>
                <li><img src="/assets/img/anim/6.png" alt=""/></li>
                <li><img src="/assets/img/anim/7.png" alt=""/></li>
                <li><img src="/assets/img/anim/8.png" alt=""/></li>
              </ul>
              <p dangerouslySetInnerHTML={{ __html: this.props.data.sideNav.newVersion }}></p>
            </div>
          </div>
        </nav>
        <Modal open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false }) } }>{ orderModal }</Modal>
      </div>
    )
  }
}
