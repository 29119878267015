import React from 'react'
import BaseComponent from './BaseComponent'
import Modal from './Modal'
import Cleave from 'cleave.js/react';

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { remove: false, add: false, card: {}, url: "", hash: "", regulationsPopup: false, regulations: [], preloader: true, showCopy: false, paymentProvider: null, companyId: null, 
      cardBT: {
        creditCardNumber: "",
        month: "1",
        year: "23",
        cvv: "",
        agreement: false
      }  
    }
    this._bind('shortFetch', 'update', 'getCard', 'remove', 'add', 'handleInputChange', 'addBTCard')
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.getCard(this.state.hash)
  }

  handleInputChange(type, event) {
    if (type && event) {      
      const target = event.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name
      let item = this.state[type]

      if (event.target.pattern) {
        let pattern = new RegExp(event.target.pattern)
        value = pattern.test(event.target.value) ? event.target.value : item[name] || "";
      }

      item[name] = value

      if( event.target.className === "input-error" ) event.target.className = ""

      if (event.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      if(name === "braintree") {
        value = value === "true" ? true : false
      }

      this.setState({
        [type]: item
      })
    } else {      
      const target = type.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name

      if (type.target.pattern) {
        let pattern = new RegExp(type.target.pattern)
        value = pattern.test(type.target.value) ? type.target.value : this.state[name] || "";
      }

      if( type.target.className === "input-error" ) type.target.className = ""

      if (type.target.id === "quantity") {
        if (parseInt(value) < 1) {
          value = 1
        } else if (parseInt(value) > this.state.maxCounter) {
          value = this.state.maxCounter
        }
      }

      if(name === "braintree") {
        value = value === "true" ? true : false
      }

      this.setState({
        [name]: value
      })
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBaseBM + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        if( endpoint === "pp/" + this.state.hash && (result.result.addingCardInProgress === true || result.result.deletingCardinProgress)) {
          this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 60000)
        }
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else if(result.answer === "IN_PROGRESS") {
        this.timer = setTimeout(() => { this.shortFetch(method, endpoint, data, success) }, 60000)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  UNSAFE_componentWillMount() {
    let location = window.location.search.replace("?", "");
    let hash = ""
    if (location) {
      let params = location.split("&");
      let parsedParams = {};
      params.forEach((item) => {
        item = item.split("=");
        if (item[0]) parsedParams[item[0]] = item[1] || null;
      });

      if(parsedParams.hash && parsedParams.ServiceID) {
        hash = parsedParams.hash
        this.setState({ hash: hash, preloader: true, showCopy: true })
        setTimeout(() => { this.getCard(hash) }, 10000)
      } else if(parsedParams.hash) {
        hash = parsedParams.hash
        this.setState({ hash: hash })
        this.getCard(hash)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    clearTimeout(this.timer2)

    window.dispatchEvent(new CustomEvent("UPDATE_STATUS", { 'detail': { status: true }}), true)
  }

  getCard(hash) {
    this.shortFetch("GET", "pp/"+hash, null, (data) => {
      // console.log(data)
      this.setState({ card: data.result, paymentProvider: data.result.provider, companyId: data.result.id, preloader: false })
    })
  //   this.setState({ card: {
  //     "cardInfo":"VISA 444444******4000 07/2022",
  //     "canDeleteCard":true,
  //     "canAddCard":true,
  //     "addingCardInProgress":false,
  //     "deletingCardinProgress":false,
  //     "orderingBlocked":false
  //  } })
  }

  remove() {
    this.shortFetch("GET", "pp/" + this.state.hash + "/remove", null, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Usunięto kartę"}}), true)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się usunąć"}}), true)
      }
      this.setState({ remove: false })
      // this.timer2 = setTimeout(() => { this.getCard(this.state.hash) }, 2000)
    })
  }

  add() {
    this.shortFetch("GET", "pp/" + this.state.hash + "/add", null, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.lang === "en" ? "Please, go to the payment system to add a card" : "Przejdź do systemu płatności, aby dodać kartę"}}), true)
        this.setState({ add: true, url: data.result.addCardRedirectUrl, card: data.result, preloader: false, showCopy: false })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd podczas dodawania karty"}}), true)
      }
      // this.timer2 = setTimeout(() => { this.getCard(this.state.hash) }, 2000)
    })
    //this.setState({ add: true, url: "https://google.com" })
  }

  addBTCard() {
    const params = {
      creditCardNumber: this.state.cardBT.creditCardNumber,
      month: this.state.cardBT.month,
      year: this.state.cardBT.year,
      cvv: this.state.cardBT.cvv,
      agreement: this.state.cardBT.agreement,
      userId: this.state.companyId,
      hash: this.state.hash
    }

    this.shortFetch("POST", "pp/bt/add", params, (data) => {
      if (data.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Dodano kartę."}}), true)
        this.setState({ add: false, cardBT: {
          creditCardNumber: "",
          month: "1",
          year: "23",
          cvv: "",
          agreement: false
        } })
        this.getCard(this.state.hash)
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd podczas dodawania karty"}}), true)
        this.getCard(this.state.hash)
      }
    })
  }

  getRegulations() {
    fetch(this._apiBaseBM + "register/dg/regulation", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      }
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        this.setState({ regulationsPopup: true, regulations: result.result.regulationLinks, preloader: false })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
      }
      this.setState({ regulationsPopup: true })
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
    })
  }

  render () {
    const page = this.props.data.cards

    let years = []

    for (var i = new Date().getYear()-100; i <= new Date().getYear()-100+10; i++) {
      years.push(i)
    }

    let removeModal = (
      <div className="password-modal">
        <div className="inputs">
          <p style={{ margin: "20px auto", maxWidth: "90%" }}>{ page.onCourse }</p>
          <h2>{ page.confirm }</h2>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={ () => { this.remove(); window.gtag('event', "platnosci_usun_karte") } } style={{ marginRight: "10px" }}><span>{ page.yes }</span></button>
          <button className="btn transparent small" id="button" onClick={ () => { this.setState({ remove: false }) } }><span>{ page.no }</span></button>
        </div>
      </div>
    )

    // let addModal = (
    //   <div className="password-modal add-card">
    //     <div className="inputs">
    //       <h2>{ page.add }</h2>
    //       <p>{ page.click }</p>
    //     </div>
    //     <div className="buttons">
    //       <a href={ this.state.url } target="_self" rel="noopener noreferrer" className="btn yellow small" id="button"><span>{ page.paymentLink }</span></a>
    //       <button className="btn transparent small" id="button2" onClick={ () => { this.setState({ add: false, url: "" }); this.getCard(this.state.hash) } }><span>{ page.close }</span></button>
    //     </div>
    //   </div>
    // )

    let addModal = (<>
      { this.state.paymentProvider === "BRAINTREE" ? <div className="password-modal add-card">
        <div className="inputs">
          <h2>{ page.add }</h2>
          <div className="input">
            <label htmlFor="creditCardNumber">{ page.creditCardNumber }</label>
            <Cleave name="creditCardNumber"
              options={{creditCard: true}}
              onChange={ this.handleInputChange.bind(null, "cardBT") } />
          </div>
          <div className="input">
            <label>{ page.date }</label>
            <div className="flex">
              <div>
                <label htmlFor="month">{ page.month }</label>
                <select id="month" name="month" value={ this.state.cardBT.month } onChange={ this.handleInputChange.bind(null, "cardBT") }>
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, i) => {
                      return (
                        <option key={ i } value={ item }>{ item }</option>
                      )
                    })
                  }
                </select>
              </div>
              <div>
                <label htmlFor="year">{ page.year }</label>
                <select id="year" name="year" value={ this.state.cardBT.year } onChange={ this.handleInputChange.bind(null, "cardBT") }>
                  {
                    years.map((item, i) => {
                      return (
                        <option key={ i } value={ item }>{ item }</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="input">
            <label htmlFor="cvv">{ page.cvv }</label>
            <input type="text" name="cvv" id="cvv" value={ this.state.cardBT.cvv } onChange={ this.handleInputChange.bind(null, "cardBT") } required/>
          </div>
          <div className="input">
            <div className="check-box"><input name="agreement" id="agreement" type="checkbox" checked={ this.state.cardBT.agreement } onChange={ this.handleInputChange.bind(null, "cardBT") }/><label htmlFor="agreement">{ page.agreement }</label></div>
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={ this.addBTCard }><span>{ page.addCardBtn }</span></button>
        </div>
        <p className="disclaimer">{ page.disclaimer }</p>
      </div> : <div className="password-modal add-card">
        <div className="inputs">
          <h2>{ page.add }</h2>
          <p>{ page.click }</p>
        </div>
        <div className="buttons">
          <a href={ this.state.url } target="_self" rel="noopener noreferrer" className="btn yellow small" id="button"><span>{ page.paymentLink }</span></a>
          <button className="btn transparent small" id="button2" onClick={ () => { this.setState({ add: false, url: "" }); this.getCard(this.state.hash) } }><span>{ page.close }</span></button>
        </div>
      </div> }
    </>
  )

    let regulationsModal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.regulations }</h2>
          <div className="input">
            <h4 dangerouslySetInnerHTML={{ __html: page.regulationsDescription }}></h4>
          </div>
          <div className="input">
            <ul>
              {/* { this.state.regulations.map((item, i) => {
                return (
                  <li key={ i }>
                    <a href={ item } target="_blank" rel="noopener noreferrer">{ page.regulations }</a>
                  </li>
                )
              }) } */}
              <li>
                <a href={ this.props.lang === "en" ? "https://itaxi.pl/terms/" : "https://itaxi.pl/regulamin/" } target="_blank" rel="noopener noreferrer">{ page.regulations }</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ () => { if(this.state.paymentProvider === "BRAINTREE") { this.setState({regulationsPopup: false, add: true}) } else { this.add(); this.setState({ regulationsPopup: false, preloader: true }) } } }><span>{ page.regulationsBtn }</span></button>
        </div>
      </div>
    )

    return (
      <section className="ordered-page">
        { this.state.preloader ? <div className="preloader-inner">
          <span></span>
          { this.state.showCopy ? <p dangerouslySetInnerHTML={{ __html: page.waitCopy  }}></p> : <p>{ page.pleaseWait }</p> }
          </div> : <div className="box">
          <h2>{ page.title }</h2>
          { this.state.card.cardInfo || this.state.card.addingCardInProgress ? <ul className="ordered-list">
            <li>
              <div className="data">
                <div className="data-inner">
                  { this.state.card.cardInfo ? <h4>{ this.state.card.cardInfo }</h4> : null }
                  { this.state.card.addingCardInProgress ? <p><strong>{ page.addingCard }</strong></p> : null }
                  { this.state.card.deletingCardinProgress ? <p><strong>{ page.deletingCard }</strong></p> : null }
                </div>
              </div>
              {/* <div>
                { this.state.card.canDeleteCard ? <button className="btn yellow small" onClick={ () => { this.setState({ remove: true }); window.gtag('event', "platnosci_usuwanie karty") } }><span>{ page.delete }</span></button> : null }
              </div> */}
            </li>
          </ul> : <ul className="ordered-list">
            <li>
              <div>
                <p><strong>{ page.noCard }</strong></p>
              </div>
              <div>
                { this.state.card.canAddCard ? <button className="btn transparent small" onClick={ () => { this.setState({ regulationsPopup: true }); window.gtag('event', "platnosci_pokaz_regulamin") } }><span>{ page.add }</span></button> : null }
              </div>
            </li>
          </ul> }
        </div> }
        <Modal open={ this.state.remove } hidePopup={ () => { this.setState({ remove: false }) } }>{ removeModal }</Modal>
        <Modal open={ this.state.add } hidePopup={ () => { this.setState({ add: false }); this.getCard(this.state.hash) } }>{ addModal }</Modal>
        <Modal open={ this.state.regulationsPopup } hidePopup={ () => { this.setState({ regulationsPopup: false }) } }>{ regulationsModal }</Modal>
      </section>
    )
  }
}
