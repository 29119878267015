import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import _ from 'lodash'
import Modal from './Modal'
import Select from './Select'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      MPK: {}, MPKS: [], itemPopup: false, id: null, rewrite: {}, data: [],
      preloader: true
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'changeValue', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'handleInputChange',
      'deleteSuccess',
      'activateItem',
      'activateSuccess',
      'update',
      'validate'
    )

    this.endpoint = "mpks"

    this.listItems()

    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ MPK: {}, itemPopup: false, id: null, rewrite: {} })
    this.listItems()
  }

  validate(fields) {
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/manage/mpk" : "/en/manage/mpk")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData.fields && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {    
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state[type]

    if (event.target.pattern) {
      let pattern = new RegExp(event.target.pattern)
      value = pattern.test(event.target.value) ? event.target.value : item[name] || "";
    }

    if( event.target.className === "input-error" ) event.target.className = ""

    item[name] = value

    this.setState({
      [type]: item
    })
  }

  listItems() {
    this.setState({ preloader: true })
    this.shortFetch("POST", this.endpoint+"", {}, this.successListItems)
  }

  successListItems(data) {    
    this.setState({ data: data.result.departments, itemPopup: false })
    setTimeout(() => { this.setState({ preloader: false }) }, 100)
  }

  addItem() {
    this.setState({ MPK: {}, itemPopup: true, action: "add" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    let department = data.result.department
    department.amountLimitGr = department.amountLimitGr && department.amountLimitGr !== 0 ? department.amountLimitGr/100 : department.amountLimitGr
    department.alertLimitGr = department.alertLimitGr && department.alertLimitGr !== 0 ? department.alertLimitGr/100 : department.alertLimitGr
    this.setState({ MPK: department, itemPopup: true, action: "edit", id: data.result.department.id })
  }

  saveItem() {
    window.gtag('event', "zarzadzanie_dodaj_mpk")
    if (this.state.MPK.id) {
      if (this.state.rewrite.id) {
        let department = {...this.state.MPK}
        department.amountLimitGr ? department.amountLimitGr = parseFloat((department.amountLimitGr+" ").replace(",", ".")) : department.amountLimitGr = null
        department.alertLimitGr ? department.alertLimitGr = parseFloat((department.alertLimitGr+" ").replace(",", ".")) : department.alertLimitGr = null

        if(department.amountLimitGr !== null) { department.amountLimitGr = department.amountLimitGr*100 }
        if(department.alertLimitGr !== null) { department.alertLimitGr = department.alertLimitGr*100 }
        this.shortFetch("POST", this.endpoint+"/edit", { department: department, rewriteTo: this.state.rewrite.id }, () => { 
          this.setState({ itemPopup: false, action: "", id: null, rewrite: {} })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
        })
      } else {
        let department = {...this.state.MPK}
        department.amountLimitGr ? department.amountLimitGr = parseFloat((department.amountLimitGr+" ").replace(",", ".")) : department.amountLimitGr = null
        department.alertLimitGr ? department.alertLimitGr = parseFloat((department.alertLimitGr+" ").replace(",", ".")) : department.alertLimitGr = null

        if(department.amountLimitGr !== null) { department.amountLimitGr = department.amountLimitGr*100 }
        if(department.alertLimitGr !== null) { department.alertLimitGr = department.alertLimitGr*100 }
        this.shortFetch("POST", this.endpoint+"/edit", { department: department }, () => { 
          this.setState({ itemPopup: false, action: "", id: null, rewrite: {} })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
        })
      }
    } else {
      let department = {...this.state.MPK}
      department.amountLimitGr ? department.amountLimitGr = parseFloat((department.amountLimitGr+" ").replace(",", ".")) : department.amountLimitGr = null
      department.alertLimitGr ? department.alertLimitGr = parseFloat((department.alertLimitGr+" ").replace(",", ".")) : department.alertLimitGr = null

      if(department.amountLimitGr !== null) { department.amountLimitGr = department.amountLimitGr*100 }
      if(department.alertLimitGr !== null) { department.alertLimitGr = department.alertLimitGr*100 }
      this.shortFetch("POST", this.endpoint+"/add", { department: department }, () => { 
        this.setState({ itemPopup: false, action: "", id: null, rewrite: {} })
        this.listItems()
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
      })
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.shortFetch("POST", this.endpoint+"/delete", { id: this.state.id, rewriteTo: this.state.rewrite.id }, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete", { id: this.state.id }, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems()
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  activateItem(id) {
    this.shortFetch("POST", this.endpoint+"/activate/"+id, {}, this.activateSuccess)
  }

  activateSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.activateSuccessfully }}), true)
    this.listItems()
  }

  changeValue(item) {
    this.setState({ rewrite: item })
  }

  render () {
    const page = this.props.data.manage

    let MPKModal = null

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      MPKModal = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.MPK.confirmRewrite : page.MPK.confirm }</strong></p>
            { this.state.action === "delete_rewrite" ? <div className="input">
              <label>{ page.MPK.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={ page.type } id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else {
      let selectedMPK = _.filter(this.state.data, { id: this.state.MPK.id })[0] || {}
      MPKModal = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label htmlFor="name">{ page.MPK.name }</label>
              <input type="text" name="name" id="name" value={ this.state.MPK.name } onChange={ this.handleInputChange.bind(null, "MPK") } required/>
            </div>
            <div className="input">
              <label htmlFor="amountLimitGr">{ page.MPK.amountLimitGr }</label>
              <input type="text" pattern="^[0-9,.]*$" min="0" name="amountLimitGr" id="amountLimitGr" value={ this.state.MPK.amountLimitGr } onChange={ this.handleInputChange.bind(null, "MPK") } required/>
            </div>
            <div className="input">
              <label htmlFor="alertLimitGr">{ page.MPK.alertLimitGr }</label>
              <input type="text" pattern="^[0-9,.]*$" min="0" name="alertLimitGr" id="alertLimitGr" value={ this.state.MPK.alertLimitGr } onChange={ this.handleInputChange.bind(null, "MPK") } required/>
            </div>
            <div className="input">
              <label htmlFor="comment">{ page.MPK.comment }</label>
              <textarea type="text" name="comment" id="comment" value={ this.state.MPK.comment } onChange={ this.handleInputChange.bind(null, "MPK") } required></textarea>
            </div>
            { this.state.action === "edit" && selectedMPK.assignUserNr > 0 ? <div className="input">
              <p><strong>{ page.MPK.rewriteHead }</strong></p>
              <label>{ page.MPK.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={ page.type } id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    return (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/manage/mpk' : '/en/manage/mpk' }>{ page.mpkName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/profiles' : '/en/manage/profiles' }>{ page.profilesName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/projects' : '/en/manage/projects' }>{ page.projectsName }</NavLink>
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.MPK.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small violet" onClick={ this.addItem }><span>{ page.add }</span></button>
              </div>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={[
              {
                Header: "Deleted",
                accessor: "deleted",
                show: false
              },
              {
                Header: "ID",
                accessor: "id",
                show: false
              },
              {
                Header: page.MPK.name,
                accessor: "name"
              },
              {
                Header: page.MPK.amountLimitGr,
                accessor: "amountLimitGr",
                Cell: row => <span>{ row.value !== 0 && row.value !== null && row.value !== undefined ? (row.value/100).toFixed(2) : row.value }</span>
              },
              {
                Header: page.MPK.totalAmount,
                accessor: "totalAmount",
                Cell: row => <span>{ row.value !== 0 && row.value !== null && row.value !== undefined ? (row.value/100).toFixed(2) : row.value }</span>
              },
              {
                Header: page.MPK.alertLimitGr,
                accessor: "alertLimitGr",
                minWidth: 170,
                Cell: row => <span>{ row.value !== 0 && row.value !== null && row.value !== undefined ? (row.value/100).toFixed(2) : row.value }</span>
              },
              {
                Header: page.MPK.comment,
                accessor: "comment"
              },
              {
                Header: page.options,
                maxWidth: 180,
                minWidth: 180,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => <div className="options">
                  { row.original.deleted ? null : <span onClick={ () => { this.editItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.edit }</span> }
                  { row.original.deleted ? null : <span onClick={ () => { this.deleteItem(row.original.id, row.original.assignUserNr) } }><img src="/assets/img/delete-icon.png" alt=""/>{ page.delete }</span> }
                  { row.original.deleted ? <span onClick={ () => { this.activateItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.activate }</span> : null }
                </div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ MPKModal }</Modal>
        </div>
    )
  }
}
